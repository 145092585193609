// Dependencies
import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { IconLink } from '@tabler/icons-react';

export default function LinkIndexSegment({ href, idLabel }) {
  const intl = useIntl();
  return (
    <div className="bg-truckBlue py-3">
      <div className="container mx-auto px-3">
        <Link to={href} className="hover:no-underline">
          <div className="text-white flex items-center justify-center gap-2">
            <IconLink size={28} />
            <div className="text-2xl font-light text-center">
              {intl.formatMessage({ id: idLabel })}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
