// Dependencies
import React from 'react';
import classNames from 'classnames';
import { IconBell } from '@tabler/icons-react';
import usePriceDrop from '@context/PriceDropContext/usePriceDrop';
import Tooltip from './tooltip';

export default function ButtonPriceDrop({ data, ...props }) {
  const { handleLaunch: handleLaunchDropPrice } = usePriceDrop();

  const tooltipId = `tooltip-price-${data.id}`;

  return (
    <>
      <button
        data-tooltip-id={tooltipId}
        data-tooltip-content="Avisar bajada de precio"
        className={classNames(
          'bg-white flex items-center rounded-full justify-center hover:scale-110 transition-all p-[4px]',
          props.className
        )}
        type="button"
        onClick={(ev) => {
          ev.preventDefault();
          handleLaunchDropPrice({ id: data.id, price: data.precio });
        }}
        aria-label={'Avisar cuando baje de precio'}
      >
        <IconBell className="stroke-truckBlue stroke-[1.5px] h-full w-full" />
      </button>
      <Tooltip id={tooltipId} />
    </>
  );
}
