// Dependencies
import React from 'react';

export default function CatParts({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 196" {...props}>
      <title>parts</title>
      <path
        fill="#272c5f"
        strokeWidth="0px"
        d="M225.6,85.5l-2-15.2,12.6-18.4-18.6-26.7-23.2,8.9-13.3-8.9-3.3-20.8-30.5-3.9-10.6,20.4-14.6,4.3-18.6-12.9-24.5,12.9,7.2,22.1-9.1,15.3-22.7,3.8-3.3,31.7,19.6,6.5s7.6,20.5,5.6,20.5-13.9,17.8-13.9,17.8l15.9,25,26.5-10.5,12.6,10.5,2,23,31.2,4.6,9.3-20.4,13.9-3.3,21.2,11.9,23.2-15.8-6.6-21.7,6.6-13.2,25.2-5.3,5.3-31.6-23.2-10.5ZM150,140.6c-24.7,0-44.8-19.1-44.8-42.6s20-42.6,44.8-42.6,44.8,19.1,44.8,42.6-20.1,42.6-44.8,42.6Z"
      />
    </svg>
  );
}
