// Dependencies
import React from 'react';

export default function InfoMapBallon({ data }) {
  return (
    <div className="block min-w-[180px] max-w-[220px] bg-white break-words">
      <div className="font-bold text-[1rem] mb-2">{data.nombre_ubicacion}</div>
      <div className="leading-5 mb-2">
        <p>{data.direccion_1}</p>
        <p>{data.direccion_2}</p>
        <p>{`${data.cp} ${data.poblacion} (${data.provincia})`}</p>
      </div>
      {data.external_url ? (
        <a
          target="_blank"
          rel="noreferrer"
          className="btn bg-gray-200 flex items-center justify-center gap-2 w-full py-1 text-blue-800 hover:text-blue-800 active:ring-0 focus:ring-0"
          href={data.external_url}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="3"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <title>Google</title>
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8" />
          </svg>
          MyBussines
        </a>
      ) : null}
    </div>
  );
}
