// Dependencies
import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import languagesProps from '@constants/languages';
import classNames from 'classnames';

function LanguageButton({ changeLocale, language, currentLocale }) {
  return (
    <button
      type="button"
      onClick={() => changeLocale(language)}
      className={classNames(
        'min-w-[85px] w-full flex flex-col items-center justify-center gap-1 rounded-md py-2 md:!py-0.5 border border-white md:flex-row focus:outline-none hover:bg-white hover:bg-opacity-30 transition-all',
        currentLocale === language ? 'border-1' : 'border-0'
      )}
    >
      <div className="text-white text-md md:text-sm">
        {languagesProps[language].label}
      </div>
      <img
        className="max-h-4"
        alt={languagesProps[language].label}
        src={languagesProps[language].flag.default}
      />
    </button>
  );
}

export default function LanguageSelector() {
  return (
    <div className="flex justify-between gap-1.5">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language) => {
            return (
              <LanguageButton
                key={language}
                language={language}
                changeLocale={changeLocale}
                currentLocale={currentLocale}
              />
            );
          })
        }
      </IntlContextConsumer>
    </div>
  );
}
