// Dependencies
import React, { useState } from 'react';
import FavoritesContext from './Context';

export default function PriceDropProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);

  function handleClose() {
    setData(null);
    setIsOpen(false);
  }

  function handleLaunch(data) {
    setData(data);
    setIsOpen(true);
  }

  return (
    <FavoritesContext.Provider
      value={{
        isOpen,
        handleLaunch,
        data,
        handleClose,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
}
