// Dependencies
import React, { useEffect } from 'react';
import BadDateContext from './Context';

export default function BadDateProvider() {
  useEffect(() => {
    const browserDate = new Date();
    fetch('http://worldtimeapi.org/api/ip')
      .then((response) => response.json())
      .then((data) => {
        const serverDate = new Date(data.datetime);
        const diff = Math.abs(browserDate - serverDate) / 1000;

        if (diff > 300) {
          alert(
            'La fecha de tu navegador parece estar mal. Por favor, corrígela.'
          );
        }
      })
      .catch((error) =>
        console.error('Error al consultar la API de tiempo:', error)
      );
  }, []);

  return <BadDateContext.Provider value={null} />;
}
