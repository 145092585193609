// Dependencies
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function HomeCarousel({ data, className }) {
  return (
    <div className={className}>
      <Swiper
        navigation={true}
        modules={[Navigation, Pagination]}
        className="swiper_carousel block"
        autoplay={{
          delay: 5000,
        }}
        pagination={{
          enabled: true,
        }}
        loop={true}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index.toString()}>
              <div className="h-[450px] select-none">
                <a
                  href={item.href}
                  alt={item.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title && (
                    <div className="w-full h-full flex items-center justify-center absolute top-0 left-0 z-[1]">
                      <div className="my-0 mx-4 bg-truckBlue/80 max-w-[400px] p-4 lg:max-w-[400px] xl:max-w-[600px]">
                        <div className="text-white text-center font-medium text-[40px] leading-[56px] lg:text-[3rem] xl:text-5xl xl:leading-[64px]">
                          {item.title}
                        </div>
                      </div>
                    </div>
                  )}
                  <GatsbyImage
                    className="carousel_image max-h-full min-h-full max-w-full min-w-full object-cover"
                    image={item.image.localFile.childImageSharp.gatsbyImageData}
                    alt={item.alt || ''}
                  />
                </a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
