// Dependencies
import React from 'react';

export default function CatRefrigerated({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.58 53.34"
      {...props}
    >
      <title>refrigerated</title>
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="329.6"
        cy="333.3"
        r="4.85"
        transform="translate(-393.64 44.5) rotate(-45)"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="311.8"
        cy="333.3"
        r="4.85"
        transform="translate(-398.85 31.91) rotate(-45)"
      />
      <path
        fill="#272c5f"
        d="M282.63,286.75v43.43h22.64l.15-.27a7.23,7.23,0,0,1,12.78,0l.14.27h4.73l.14-.27a7.23,7.23,0,0,1,12.78,0l.14.27h7V286.75a.58.58,0,0,0-.57-.57H283.2a.58.58,0,0,0-.57.57"
        transform="translate(-254.5 -286.18)"
      />
      <path
        fill="#272c5f"
        d="M275.83,295.63h9.1v-6.54H274.37a.57.57,0,0,0-.55.72l1.47,5.41a.56.56,0,0,0,.54.41"
        transform="translate(-254.5 -286.18)"
      />
      <polygon
        fill="#272c5f"
        points="20.9 43.99 30.43 43.99 30.43 37.45 19.12 37.45 20.9 43.99"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="267.24"
        cy="333.3"
        r="4.85"
        transform="translate(-411.9 0.41) rotate(-45)"
      />
      <path
        fill="#272c5f"
        d="M260.34,298.78l-5.8,15.85a.43.43,0,0,0,0,.19v14.79a.57.57,0,0,0,.57.57h5.65l.14-.27a7.23,7.23,0,0,1,12.78,0l.14.27h5.78V299a.57.57,0,0,0-.57-.56H260.87a.55.55,0,0,0-.53.37m15.89,15.94H258.1l4.52-13.16h13.61Z"
        transform="translate(-254.5 -286.18)"
      />
      <path
        fill="#fff"
        d="M312.85,294.21a.92.92,0,0,0-.89.92v2.92l-1.77-1.76a1,1,0,0,0-1.26,0,1,1,0,0,0,0,1.3l3,3V305l-3.8-2.19L307,298.63a.92.92,0,0,0-.85-.68,1,1,0,0,0-.9,1.15l.65,2.4L303.4,300a.9.9,0,1,0-.9,1.56l2.54,1.47-2.42.64a.9.9,0,1,0,.47,1.74l4.16-1.11,3.8,2.18-3.8,2.19c-1.48-.29-3-1-4.43-1.16a.9.9,0,0,0-.2,1.78L305,310l-2.54,1.46a.94.94,0,0,0-.34,1.24,1,1,0,0,0,1.24.33l2.54-1.46-.65,2.41a.9.9,0,1,0,1.75.45l1.12-4.14,3.8-2.18v4.37l-3,3a.93.93,0,0,0,0,1.31.94.94,0,0,0,1.32,0L312,315v2.93a.9.9,0,1,0,1.8,0V315l1.77,1.76a.94.94,0,0,0,1.32,0,.93.93,0,0,0,0-1.31l-3.05-3v-4.37l3.8,2.18,1.12,4.14a.9.9,0,1,0,1.75-.45l-.65-2.41,2.54,1.46a.9.9,0,1,0,.9-1.57L320.68,310l2.42-.64a.9.9,0,0,0-.23-1.78c-1.45.32-2.94.76-4.4,1.15l-3.8-2.19,3.8-2.18,4.16,1.12a1,1,0,0,0,1.16-.62.94.94,0,0,0-.69-1.12l-2.42-.64,2.54-1.47a1,1,0,0,0,.32-1.29c-.14-.39-.75-.45-1.22-.27l-2.54,1.46.65-2.4a.94.94,0,0,0-.93-1.14.91.91,0,0,0-.82.67l-1.12,4.14-3.8,2.19v-4.37l3.05-3a.93.93,0,0,0,0-1.31,1,1,0,0,0-1.32.05l-1.77,1.76v-2.92a.94.94,0,0,0-.91-.92"
        transform="translate(-254.5 -286.18)"
      />
      <line
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="1.5px"
        x1="6.74"
        y1="25.84"
        x2="6.74"
        y2="29.86"
      />
    </svg>
  );
}
