// Dependencies
import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const MyTooltip = ({ ...props }) => {
  return (
    <Tooltip
      className="!bg-white !text-truckBlue !font-medium"
      place="left-start"
      offset={15}
      {...props}
    />
  );
};

export default MyTooltip;
