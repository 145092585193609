// Dependencies
import React from 'react';
import { BannerBuy, BannerFinancial } from '@components';

const banners = [
  { component: <BannerBuy /> },
  { component: <BannerFinancial /> },
];

export default banners;
