// Dependencies
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { prettyNumber, formatDate } from '@lib/utils';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { calcMonthly } from '@lib/financial';
import ButtonWhatsapp from './buttonWhatsapp';
import classNames from 'classnames';
import ButtonFavoriteHeart from './buttonFavoriteHeart';
import ButtonPriceDrop from './buttonPriceDrop';

function FinancialLabel({ monthlyFee }) {
  const intl = useIntl();
  if (!monthlyFee) return null;
  return (
    <div className="flex justify-between absolute w-full bg-green-700 bg-opacity-80 text-white z-[20] bottom-0 text-sm py-[2px] px-2">
      <div className="font-medium">Financiable</div>
      <div className="font-light">
        {prettyNumber(
          monthlyFee,
          0,
          intl.formatMessage({ id: 'financing.euro_month' })
        )}
      </div>
    </div>
  );
}

function SoldLabel({ isSold }) {
  const intl = useIntl();
  if (!isSold) return null;
  return (
    <div className="absolute z-[20] top-[26px] -left-[42px]">
      <div className="rotate-[-45deg] bg-trucksur-600 text-white font-bold py-1 w-[170px] text-center">
        {intl.formatMessage({ id: 'misc_sold' }).toUpperCase()}
      </div>
    </div>
  );
}

export default function VehiclesListItem({ data, proxEntImg, interest }) {
  const intl = useIntl();
  const isReserved = data.estado === 'Reservado';
  const isSold = data.estado === 'Vendido';
  const isBankable = data.es_financiable;

  const monthlyFee = isBankable
    ? calcMonthly({
        amount: data.financ_pvp,
        entry: data.financ_entrada,
        months: data.financ_plazo,
        residualValue: data.financ_valor_res,
        interest,
      })
    : null;

  return (
    <Link
      to={`/vehiculo/${data.seo_name}`}
      className={classNames(
        'relative h-full overflow-hidden rounded-xl hover:no-underline w-[320px] md:w-auto',
        {
          'bg-gray-200': isSold || isReserved,
        }
      )}
    >
      <div className="h-full absolute z-[45] right-0 top-0 flex flex-col gap-2 pt-[10px] pr-[10px]">
        <ButtonWhatsapp data={data} />
        <ButtonFavoriteHeart className="w-8 h-8" id={data.id} />
        <ButtonPriceDrop className="w-8 h-8" data={data} />
      </div>

      <div className="shadow-md h-full w-full border rounded-xl border-gray-300 overflow-hidden grid grid-cols-1 justify-between">
        <div className="relative h-[210px] overflow-hidden rounded-bl-xl rounded-br-xl">
          <FinancialLabel monthlyFee={monthlyFee} />
          <SoldLabel isSold={isReserved || isSold} />
          <GatsbyImage
            className="h-full w-full"
            image={
              getImage(data?.fields?.main_image?.localFile) ||
              getImage(proxEntImg)
            }
            alt={data.matricula}
          />
        </div>
        <div className="py-3 px-3 h-full">
          <div className="flex gap-1">
            <span className="text-truckBlue text-lg font-bold text-left">
              {data.titulo}
            </span>
          </div>

          <div className="inline-block gap-2">
            {data.precio_bajada && (
              <span className="text-red-500 font-light line-through mr-1">
                {prettyNumber(data.precio_bajada, 0, '€')}
              </span>
            )}
            <span className="text-xl font-light text-left text-truckBlue">
              {prettyNumber(data.precio, 0, '€')}
            </span>
          </div>

          <hr className="my-1.5" />
          <div className="grid grid-cols-1 gap-2 text-md text-gray-600">
            <div className="text-left">
              <div className="font-medium text-sm text-truckBlue">
                {intl.formatMessage({ id: 'vehicle.fecha_de_matriculacion' })}
              </div>
              <div className="font-light">
                {formatDate(data.fecha_de_matriculacion)}
              </div>
            </div>
            <hr />
            <div className="flex justify-between">
              <div className="font-medium text-sm text-truckBlue">
                {intl.formatMessage({ id: 'vehicle.matricula' })}
              </div>
              <div className="font-light">{data.matricula}</div>
            </div>
            <hr />
            <div className="flex justify-between">
              <div className="font-medium text-sm text-truckBlue">
                {intl.formatMessage({ id: 'misc_kilometres' })}
              </div>
              <div className="font-light">
                {prettyNumber(data.kms_segun_tacografo)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
