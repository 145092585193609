// Dependencies
import React from 'react';

export default function CatTractorHead({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57.91 45.09"
      {...props}
    >
      <title>tractor_head</title>
      <circle
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        fill="#fff"
        cx="281.58"
        cy="437.25"
        r="4.85"
        transform="translate(-495.55 -71.21) rotate(-45)"
      />
      <path
        fill="#272c5f"
        d="M268.84,427.6v6a.58.58,0,0,0,.57.57h5.65l.14-.27a7.23,7.23,0,0,1,12.78,0l.14.27H298.9V427.6"
        transform="translate(-268.84 -398.39)"
      />
      <circle
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        fill="#fff"
        cx="320.53"
        cy="437.25"
        r="4.85"
        transform="translate(-484.15 -43.68) rotate(-45)"
      />
      <path
        fill="#272c5f"
        d="M320.54,427.6V430a7.23,7.23,0,0,0-6.39,3.85l-.14.27H294.74L293,427.6Z"
        transform="translate(-268.84 -398.39)"
      />
      <path
        fill="#272c5f"
        d="M295.76,398.39s-13.16.88-17.56,1a8.48,8.48,0,0,0-7.4,4.32c-1.15,2.21-1.77,5.39-1.8,10.15,0,8.29-.14,17.12-.16,19.71a.57.57,0,0,0,.57.57h3.81l2.58-4.71,12.17-.56,2,5.27h6.37V399a.58.58,0,0,0-.57-.57m-7.93,13.5-12.23,2.83h-4.18a27.44,27.44,0,0,1,1.54-9.94,2.85,2.85,0,0,1,2.65-1.83h12.22Z"
        transform="translate(-268.84 -398.39)"
      />
      <rect fill="#272c5f" x="23.39" y="25.02" width="9.64" height="6.98" />
    </svg>
  );
}
