// Dependencies
import { createContext } from 'react';

const FavoritesContext = createContext({
  isFavorite: () => false,
  alternateFavorite: () => {},
  favorites: [],
});

export default FavoritesContext;
