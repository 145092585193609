// Dependencies
import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import {
  Recaptcha,
  Button,
  InputTextForm,
  InputCheckbox,
  Card,
} from '@components';
import { LuSend } from 'react-icons/lu';
import InputTextAreaForm from '@components/inputTextAreaForm';
import useContactForm from './useContactForm';
import PrivacityCheckbox from '@components/privacityCheckbox';

export default function ContactForm() {
  const intl = useIntl();
  const { form, onSubmit, fetching, recaptchaRef } = useContactForm();
  const errors = form.formState.errors;

  return (
    <Card>
      <Card.Header>
        <span className="font-medium">
          {intl.formatMessage({ id: 'misc_contact.title' })}
        </span>
      </Card.Header>
      <Card.Body>
        <form
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <InputTextForm
            isRequired
            id="full_name"
            label={intl.formatMessage({
              id: 'misc_full_name',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_full_name',
            })}
            errors={errors}
            {...form.register('full_name')}
          />
          <InputTextForm
            isRequired
            type="tel"
            id="phone"
            label={intl.formatMessage({
              id: 'misc_phone',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_phone',
            })}
            errors={errors}
            {...form.register('phone')}
          />
          <InputTextForm
            className="md:col-span-full lg:col-span-1"
            isRequired
            type="email"
            id="email"
            label={intl.formatMessage({
              id: 'misc_email',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_email',
            })}
            errors={errors}
            {...form.register('email')}
          />
          <InputTextAreaForm
            className="md:col-span-full"
            isRequired
            id="message"
            label={intl.formatMessage({
              id: 'misc_contact.message',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_contact.message',
            })}
            errors={errors}
            rows={3}
            {...form.register('message')}
          />

          <PrivacityCheckbox form={form} className="md:col-span-full" />
          <InputCheckbox
            className="md:col-span-full"
            defaultChecked={true}
            {...form.register('accept_newsletter')}
            id="accept_newsletter"
            label={intl.formatMessage({
              id: 'misc_accept_newsletter',
            })}
            errors={errors}
          />
          <div className="md:col-span-full flex justify-end items-end">
            <Button color="trucksur" type="submit" isLoading={fetching}>
              <div className="flex w-fit mx-auto">
                <LuSend className="h-5 w-5" />
                <div className="ml-[5px]">
                  <FormattedMessage id="misc_send" />
                </div>
              </div>
            </Button>
          </div>
        </form>
        <Recaptcha ref={recaptchaRef} />
      </Card.Body>
    </Card>
  );
}
