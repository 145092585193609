// Dependencies
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { Button, InputRangeSlider, Card } from '@components';
import useVehicleFinancial from '@hooks/useVehicleFinancial';

export default function VehicleFinancial({
  dataVehicle,
  dataFinancial,
  ...props
}) {
  const intl = useIntl();
  const fim = intl.formatMessage;

  const {
    values,
    defValues,
    entradaValues,
    plazoValues,
    isAlertPlazo,
    control,
    handleSubmit,
  } = useVehicleFinancial({
    dataVehicle,
    dataFinancial,
  });

  return (
    <Card {...props}>
      <Card.Header className="font-bold">
        {fim({ id: 'financing.calculate' })}
      </Card.Header>
      <Card.Body className="mx-4">
        <div className="mb-[2rem]">
          <InputRangeSlider
            label={values.entrada}
            control={control}
            name="entrada"
            min={0}
            defaultValues={[0, 0]}
            max={entradaValues.length - 1}
          />
        </div>
        <div className="mb-[2rem]">
          <InputRangeSlider
            label={values.plazo}
            control={control}
            name="plazo"
            min={0}
            defaultValues={[0, 0]}
            max={plazoValues.length - 1}
          />

          {isAlertPlazo && (
            <div className="text-[0.8rem] text-truckBlue">
              * El plazo seleccionado mas la antigüedad del vehículo es mayor o
              igual a 11 años. La financiación de este vehículo es dudosa.
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 mb-3">
          <div className="p-1 h-full flex items-center justify-center border-[2px] border-truckBlue font-bold text-truckBlue rounded-md text-center">
            {values.cuota}
          </div>
          <Button color="trucksur" className="w-full" onClick={handleSubmit}>
            <div className="flex w-fit mx-auto">
              <FaSearch className="h-5 w-5" />
              <span className="ml-[5px]">
                <FormattedMessage id="financing.main_button" />
              </span>
            </div>
          </Button>
        </div>

        <hr className="my-1.5" />
        <div className="text-truckBlue text-[0.8rem]">
          <p>{fim({ id: 'financing.text_condition' })}</p>
        </div>
        {defValues && (
          <div>
            <hr className="my-1.5" />
            <div className="text-sm">
              <div className="text-lg font-bold mb-2">
                {fim({ id: 'financing.default' })}
              </div>
              <p>
                <span className="font-bold">
                  {fim({ id: 'financing.entry' })}:{' '}
                </span>
                <span>{defValues.entrada}</span>
              </p>
              <p>
                <span className="font-bold">
                  {fim({ id: 'financing.period' })}:{' '}
                </span>
                <span>{defValues.plazo}</span>
              </p>
              <p>
                <span className="font-bold">
                  {fim({ id: 'financing.fee' })}:{' '}
                </span>
                <span>{defValues.cuota}</span>
              </p>
              {defValues.condiciones && (
                <p>
                  <span className="font-bold">
                    {fim({ id: 'financing.observations' })}
                  </span>
                  <span>{defValues.condiciones}</span>
                </p>
              )}
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
