// Dependencies
import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Recaptcha, Button } from '@components';
import InputTextForm from '@components/inputTextForm';
import InputCheckbox from '@components/inputCheckbox';
import InputSelectForm from '@components/inputSelectForm';
import InputDateForm from '@components/inputDateForm';
import Card from '@components/card';
import { LuSend } from 'react-icons/lu';
import useBuyForm from './useBuyForm';
import PrivacityCheckbox from '@components/privacityCheckbox';

export default function BuyForm() {
  const intl = useIntl();
  const {
    form,
    fetching,
    countryValues,
    provinceValues,
    onSubmit,
    recaptchaRef,
  } = useBuyForm();
  const errors = form.formState.errors;

  return (
    <Card>
      <Card.Header>
        <span className="font-medium">
          {intl.formatMessage({ id: 'buy_subtitle' })}
        </span>
      </Card.Header>
      <Card.Body>
        <form
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"
        >
          <InputTextForm
            isRequired
            id="full_name"
            label={intl.formatMessage({
              id: 'misc_full_name',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_full_name',
            })}
            errors={errors}
            {...form.register('full_name')}
          />
          <InputTextForm
            isRequired
            type="tel"
            id="phone"
            label={intl.formatMessage({
              id: 'misc_phone',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_phone',
            })}
            errors={errors}
            {...form.register('phone')}
          />
          <InputTextForm
            className="md:col-span-full lg:col-span-1"
            isRequired
            type="email"
            id="email"
            label={intl.formatMessage({
              id: 'misc_email',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_email',
            })}
            errors={errors}
            {...form.register('email')}
          />
          <InputSelectForm
            isRequired
            id="country"
            label={intl.formatMessage({ id: 'misc_country' })}
            errors={errors}
            values={countryValues}
            {...form.register('country')}
          />
          <InputSelectForm
            isRequired
            id="province"
            label="Provincia"
            errors={errors}
            values={provinceValues}
            {...form.register('province')}
          />

          <InputTextForm
            type="text"
            id="brand"
            label={intl.formatMessage({
              id: 'misc_brand',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_brand',
            })}
            errors={errors}
            {...form.register('brand')}
          />
          <InputTextForm
            type="text"
            id="model"
            label={intl.formatMessage({
              id: 'misc_model',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_model',
            })}
            errors={errors}
            {...form.register('model')}
          />
          <InputDateForm
            id="date_matriculation"
            label={intl.formatMessage({
              id: 'misc_date_matriculation',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_date_matriculation',
            })}
            errors={errors}
            {...form.register('date_matriculation')}
          />

          <div className="flex flex-col gap-4 md:col-span-2 lg:col-span-3">
            <InputCheckbox
              {...form.register('accept_phone_call')}
              id="accept_phone_call"
              label={intl.formatMessage({ id: 'misc_accept_phone_call' })}
              errors={errors}
            />

            <PrivacityCheckbox form={form} />
            <InputCheckbox
              defaultChecked={true}
              {...form.register('accept_newsletter')}
              id="accept_newsletter"
              label={intl.formatMessage({
                id: 'misc_accept_newsletter',
              })}
              errors={errors}
            />
          </div>

          <div className="md:col-span-full flex justify-end items-end">
            <Button color="trucksur" type="submit" isLoading={fetching}>
              <div className="flex w-fit mx-auto">
                <LuSend className="h-5 w-5" />
                <div className="ml-[5px]">
                  <FormattedMessage id="misc_send" />
                </div>
              </div>
            </Button>
          </div>
        </form>
        <Recaptcha ref={recaptchaRef} />
      </Card.Body>
    </Card>
  );
}
