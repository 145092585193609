// Dependencies
import { fetcher, fetcherBlob } from './apiInstance';
import { downloadFile } from '../lib/utils';

export function fetchCreateBuyRequest(data) {
  return fetcher(`buy_requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export function fetchCreateInfoRequest(data) {
  return fetcher(`info_requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function fetchCreateContactRequest(data) {
  return fetcher(`contact_requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function fetchCreateEmptyVehicles(data) {
  return fetcher(`empty_vehicle`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function fetchCreateNewsletter(data) {
  return fetcher(`newsletter_users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function fetchCreatePriceDrop(data) {
  return fetcher(`price_drop`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export async function downloadTechnicalSheet(params, filename) {
  const searchParams = new URLSearchParams(params);
  const blob = await fetcherBlob(
    `vehicles/pdf_datasheet?${searchParams.toString()}`
  );
  downloadFile(blob, 'application/pdf', filename);
}
