// Dependencies
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-intl';
import classNames from 'classnames';

const ImagesQuery = graphql`
  {
    vehicles: file(relativePath: { eq: "banner_buy/vehicles.png" }) {
      childImageSharp {
        gatsbyImageData(width: 363, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`;

export default function BannerBuy({ className }) {
  const data = useStaticQuery(ImagesQuery);
  return (
    <div
      className={classNames(
        'w-full bg-gradient-to-t from-gray-100 to-gray-300 rounded-xl',
        className
      )}
    >
      <div className="grid grid-cols-1 md:grid-cols-[1fr_auto] h-full">
        <div className="flex flex-col justify-center bg-truckBlue text-white rounded-tl-xl rounded-bl-0 rounded-tr-xl md:rounded-bl-xl md:rounded-tr-0 py-4 pl-4 pr-[20%] md:[clip-path:polygon(0_0,80%_0,100%_100%,0_100%)]">
          <p className="text-2xl lg:text-3xl font-medium">EN TRUCKSUR</p>
          <p className="text-2xl lg:text-3xl font-medium">
            COMPRAMOS TU VEHÍCULO
          </p>
          <p className="text-sm lg:text-lg font-light">
            ¿PENSANDO EN CAMBIAR DE CAMIÓN?
          </p>
          <p className="text-sm lg:text-lg font-light">
            ¿BUSCAS LA MEJOR TASACIÓN?
          </p>
          <p className="text-sm lg:text-lg font-light">
            CONTACTA CON NOSOTROS Y TE LA OFRECEMOS SIN COMPROMISO
          </p>
        </div>

        <div className="grid grid-cols-1 gap-4 my-auto py-4 px-4 justify-items-center">
          <GatsbyImage
            className="md:w-[240px] lg:w-[363px]"
            alt="banner"
            image={data?.vehicles?.childImageSharp?.gatsbyImageData}
          />

          <Link
            to="/compramos-tu-vehiculo"
            className="text-lg py-1 px-3 text-white bg-trucksur-600 hover:bg-trucksur-700 hover:text-white hover:no-underline transition-all"
          >
            + INFORMACIÓN
          </Link>
        </div>
      </div>
    </div>
  );
}
