// prettier-ignore
const fieldsVehicleType = {
	"Semirremolques y Carrocerías": [
		"suspension_trasera",
		"sistema_de_frenos",
		"adr",
		"nivel_ecologico",
		"tipo_de_carroceria",
		"marca_de_carroceria",
		"medidas_interiores_de_la_carroceria",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
	"Cabezas tractoras": [
		"kms_segun_tacografo",
		"tipo_de_cabina",
		"suspension_delantera",
		"suspension_trasera",
		"distancia_entre_ejes",
		"caja_de_cambios",
		"sistema_de_frenos",
		"freno_auxiliar",
		"adr",
		"combinacion_de_depositos",
		"nivel_ecologico",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
	Construccion: [
		"kms_segun_tacografo",
		"tipo_de_cabina",
		"suspension_delantera",
		"suspension_trasera",
		"distancia_entre_ejes",
		"caja_de_cambios",
		"sistema_de_frenos",
		"freno_auxiliar",
		"adr",
		"combinacion_de_depositos",
		"nivel_ecologico",
		"tipo_de_carroceria",
		"marca_de_carroceria",
		"medidas_interiores_de_la_carroceria",
		"pma",
		"tara",
		"mma_1st_axis",
		"mma_2nd_axis",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
	"Vehículos ligeros": [
		"kms_segun_tacografo",
		"caja_de_cambios",
		"nivel_ecologico",
		"tipo_de_carroceria",
		"marca_de_carroceria",
		"medidas_interiores_de_la_carroceria",
		"pma",
		"distancia_entre_ejes",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
	"Vehículos frigoríficos": [
		"kms_segun_tacografo",
		"tipo_de_cabina",
		"suspension_delantera",
		"suspension_trasera",
		"distancia_entre_ejes",
		"caja_de_cambios",
		"sistema_de_frenos",
		"freno_auxiliar",
		"adr",
		"combinacion_de_depositos",
		"nivel_ecologico",
		"tipo_de_carroceria",
		"marca_de_carroceria",
		"medidas_interiores_de_la_carroceria",
		"pma",
		"tara",
		"mma_1st_axis",
		"mma_2nd_axis",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
	"Vehículos Rígidos": [
		"kms_segun_tacografo",
		"tipo_de_cabina",
		"suspension_delantera",
		"suspension_trasera",
		"distancia_entre_ejes",
		"caja_de_cambios",
		"sistema_de_frenos",
		"freno_auxiliar",
		"adr",
		"combinacion_de_depositos",
		"nivel_ecologico",
		"tipo_de_carroceria",
		"marca_de_carroceria",
		"medidas_interiores_de_la_carroceria",
		"pma",
		"tara",
		"mma_1st_axis",
		"mma_2nd_axis",
		"capacidad_de_elevacion_kg",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
	Autobuses: [
		"kms_segun_tacografo",
		"suspension_delantera",
		"suspension_trasera",
		"caja_de_cambios",
		"freno_auxiliar",
		"adr",
		"nivel_ecologico",
		"marca_de_carroceria",
		"medidas_interiores_de_la_carroceria",
		"modelo_de_neumaticos_delanteros",
		"modelo_de_neumaticos_traseros",
		"modelo_de_neumaticos_delanteros_3_eje",
		"modelo_de_neumaticos_delanteros_4_eje",
		"estado_neumaticos_delanteros",
		"estado_neumaticos_traseros",
		"estado_neumaticos_delanteros_3_eje",
		"estado_neumaticos_delanteros_4_eje",
	],
};

export default fieldsVehicleType;
