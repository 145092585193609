// Dependencies
import React from 'react';
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { LanguageSelector } from '@components';
import classNames from 'classnames';
import useFavorites from '@context/FavoritesContext/useFavorites';

function FavoriteButton() {
  const { isOpen, setIsOpen, favorites } = useFavorites();
  return (
    <button
      className="relative bg-transparent focus:outline-none hidden md:block"
      type="button"
      onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
    >
      {favorites.length ? (
        <div className="absolute w-3 h-3 bg-red-600 z-[90] rounded-full -top-[2px] -right-[4px]" />
      ) : null}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        className="w-7 h-7 stroke-white"
      >
        <title>Favoritos</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
      </svg>
    </button>
  );
}

export default function HeaderButtons(props) {
  const intl = useIntl();
  return (
    <div
      className={classNames('grid grid-cols-1 gap-5 md:gap-3', props.className)}
    >
      <div className="md:order-2 grid grid-cols-1 md:grid-cols-[1fr_1fr_auto] gap-3">
        <Link
          className="text-white md:text-sm py-2 md:py-1.5 outline outline-1 outline-gray-500 bg-gray-500 rounded-md px-2 text-center"
          to="/compramos-tu-vehiculo/"
        >
          <FormattedMessage id="menu_buy" />
        </Link>
        <a
          className="text-white md:text-sm py-2 md:py-1.5 outline outline-1 rounded-md px-2 text-center"
          href="tel:+34900100220"
        >
          {intl.formatMessage({ id: 'misc_call_us' })} +34 900 100 220
        </a>
        <FavoriteButton />
      </div>
      <LanguageSelector />
    </div>
  );
}
