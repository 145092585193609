// Dependencies
import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import ImagesVehicleCarousel, {
  NextArrow,
  PrevArrow,
} from '@components/imagesVehicleCarousel';

export default function CarouselModal({
  isOpen,
  handleClose,
  data,
  initialIndexSlide,
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    function handler(ev) {
      const isClickOutside = !contentRef?.current?.contains(ev.target);
      if (isOpen && isClickOutside) handleClose();
      return;
    }
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [isOpen, handleClose]);

  const slickProps = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
  };

  return isOpen ? (
    <div
      className={classNames(
        'fixed z-[100] left-0 top-0 w-full h-full overflow-auto bg-black bg-opacity-50 flex items-center justify-center'
      )}
    >
      {/*content*/}
      <div
        ref={contentRef}
        className="w-full max-w-[1280px] mx-1 overflow-hidden border-0 rounded-lg shadow-lg flex flex-col p-1 bg-white outline-none focus:outline-none"
      >
        {/*close button*/}
        <div className="flex justify-end py-1.5 ">
          <button
            className="flex justify-center items-center"
            type="button"
            onClick={handleClose}
            aria-label="Cerrar modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              className="w-7 h-7 stroke-gray-500 hover:stroke-[3px] hover:stroke-gray-600 transition-all"
            >
              <title>Cerrar</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {/*body*/}
        <div className="relative flex-auto aspect-[640/480]">
          <ImagesVehicleCarousel
            images={data}
            slickProps={slickProps}
            initialIndexSlide={initialIndexSlide}
          />
        </div>
      </div>
    </div>
  ) : null;
}
