// Dependencies
import React from 'react';
import classNames from 'classnames';

function Card({ children, className, ...props }) {
  return (
    <div
      className={classNames(
        'border-[1px] border-gray-300 rounded-md overflow-hidden relative',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

function CardHeader({ children, className, ...props }) {
  return (
    <div
      className={classNames(
        'border-b border-gray-300 bg-gray-100 p-3',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

function CardBody({ children, className, ...props }) {
  return (
    <div className={classNames('p-3', className)} {...props}>
      {children}
    </div>
  );
}

function CardFooter({ children, className, ...props }) {
  return (
    <div
      className={classNames(
        'border-t border-gray-300 bg-gray-100 p-3',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
