// Dependencies
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

export default function VehicleSegmentTitle({ qty, idTitle, ...props }) {
  const intl = useIntl();
  const vehiclesStockLabel = intl.formatMessage(
    { id: 'misc_stock' },
    { count: qty }
  );

  return (
    <div className={props.className}>
      <h1 className="text-3xl font-normal text-truckGold">
        {intl.formatMessage({ id: idTitle })}
      </h1>
      {qty ? <div>{vehiclesStockLabel}</div> : null}
    </div>
  );
}
