// Dependencies
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export default function ListVehiclesPricesDetail({ className }) {
  return (
    <div
      className={classNames(
        'mt-8 bg-slate-100 py-2 px-3 rounded-md flex gap-2 items-center font-light',
        className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <title>Información</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
      <span>
        <FormattedMessage id="misc_footer_price" />
      </span>
    </div>
  );
}
