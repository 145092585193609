// Dependencies
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

const ImagesQuery = graphql`
  {
    vehicles: file(relativePath: { eq: "banner_parts/parts.png" }) {
      childImageSharp {
        gatsbyImageData(width: 363, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`;

export default function BannerParts({ className }) {
  const data = useStaticQuery(ImagesQuery);
  return (
    <div className={classNames('w-full bg-trucksur-600 rounded-xl', className)}>
      <div className="grid grid-cols-1 md:grid-cols-[1fr_auto] h-full">
        <div className="flex flex-col justify-center text-white rounded-tl-xl rounded-bl-0 rounded-tr-xl md:rounded-bl-xl md:rounded-tr-0 py-4 px-4 ">
          <p className="text-2xl lg:text-3xl font-light">
            Encuentra el repuesto usado y reacondicionado que estás buscando
            para tu vehículo industrial en TrucksurParts
          </p>
        </div>

        <div className="relative h-full min-h-[204px]">
          <GatsbyImage
            className="w-full h-full rounded-b-xl md:rounded-b-none md:rounded-r-xl"
            alt="banner"
            image={data?.vehicles?.childImageSharp?.gatsbyImageData}
          />
          <div className="absolute flex justify-center items-end w-full h-full bottom-0 left-0">
            <a
              href="https://www.trucksurparts.com/"
              target="_blank"
              rel="noreferrer"
              className="mb-4 text-lg py-1 px-3 text-white bg-trucksur-600 hover:bg-trucksur-700 hover:text-white hover:no-underline transition-all"
            >
              + INFORMACIÓN
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
