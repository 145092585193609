// Dependencies
import React, { useState, useEffect, useMemo } from 'react';
import { getDisplayedFavorites } from '@lib/utils';
import toast from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import FavoritesContext from './Context';

const LOCALSTORAGE_KEY = '_favorites';

export default function FavoritesProvider({ children }) {
  const [favorites, setFavorites] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    allVehicle: { edges: vehiclesEdges },
    prox_ent_img,
  } = useStaticQuery(graphql`
    query {
      allVehicle {
        edges {
          node {
            _id
            titulo
            seo_name
            matricula
            fecha_de_matriculacion
            fields {
              main_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 100
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
      prox_ent_img: file(relativePath: { eq: "proxima_entrada.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const vehicles = vehiclesEdges.map(({ node: { _id, ...vehicle } }) => ({
    id: _id,
    ...vehicle,
    proxEntImg: prox_ent_img,
  }));

  useEffect(() => {
    const strFavorites = localStorage.getItem(LOCALSTORAGE_KEY);
    const favorites = JSON.parse(strFavorites);
    if (favorites) {
      setFavorites(favorites);
    }
  }, []);

  function alternateFavorite(id) {
    let newFavorites = favorites;

    if (favorites.includes(id)) {
      toast('❌ Vehículo eliminado de favoritos', {
        className: 'text-sm',
      });
      newFavorites = favorites.filter((favId) => favId !== id);
    } else {
      toast('✅ Vehículo añadido a favoritos', {
        className: 'text-sm',
      });
      newFavorites = [...favorites, id];
    }

    setFavorites(newFavorites);
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newFavorites));
  }

  function isFavorite(id) {
    return favorites.includes(id);
  }

  const displayedFavorites = useMemo(
    () => getDisplayedFavorites(favorites, vehicles) || [],
    [favorites, vehicles]
  );

  return (
    <FavoritesContext.Provider
      value={{
        isFavorite,
        alternateFavorite,
        favorites,
        setIsOpen,
        isOpen,
        displayedFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
}
