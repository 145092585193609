// Dependencies
import React from 'react';

export default function CatCars({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374 209" {...props}>
      <title>cars</title>
      <g
        transform="translate(0.000000,209.000000) scale(0.100000,-0.100000)"
        fill="#272c5f"
        stroke="none"
      >
        <path
          d="M1860 1335 c-300 -36 -586 -128 -835 -266 -115 -64 -128 -68 -387
-113 -414 -74 -553 -147 -582 -308 -4 -24 -13 -39 -27 -43 -18 -6 -19 -14 -17
-128 l3 -120 123 -33 c67 -18 135 -35 151 -39 l28 -6 0 80 c0 67 5 90 27 135
34 69 101 129 175 157 83 31 205 24 280 -16 98 -54 156 -150 168 -282 l6 -63
106 0 c92 1 1524 28 1527 29 1 1 3 25 6 53 10 118 98 238 204 277 78 30 183
28 249 -3 94 -43 170 -153 190 -275 l7 -43 157 4 c144 3 159 5 201 28 24 14
58 45 75 68 26 38 30 52 30 110 0 57 -5 75 -33 123 -31 54 -32 61 -32 167 0
118 -8 155 -37 170 -10 6 -83 20 -162 32 -121 18 -151 26 -185 49 -176 121
-524 229 -835 261 -137 13 -450 11 -581 -5z m224 -117 c-3 -29 -9 -91 -12
-138 l-7 -85 -255 -6 c-140 -4 -365 -9 -500 -13 l-245 -6 30 25 c47 39 210
119 321 158 177 62 450 113 617 116 l57 1 -6 -52z m423 28 c114 -18 234 -44
253 -56 11 -7 12 -22 3 -90 -6 -44 -12 -83 -14 -85 -3 -2 -141 -7 -308 -11
l-304 -7 7 104 c4 57 10 119 13 138 l6 34 126 -6 c69 -4 167 -13 218 -21z
m475 -131 c44 -19 101 -47 127 -62 l46 -28 -160 -6 c-88 -4 -163 -4 -166 0 -4
3 -2 37 3 75 l10 68 30 -6 c16 -3 66 -21 110 -41z m-2652 -395 l0 -100 -111 0
c-110 0 -110 0 -103 23 21 68 74 129 139 158 73 34 75 32 75 -81z"
        />
        <path
          d="M583 581 c-134 -34 -218 -176 -179 -304 19 -63 56 -110 114 -144 62
-37 165 -39 227 -6 137 73 175 242 82 365 -54 71 -161 110 -244 89z m118 -116
c90 -47 90 -193 0 -240 -64 -32 -145 -11 -181 48 -71 115 61 254 181 192z"
        />
        <path
          d="M2860 575 c-164 -53 -223 -256 -112 -385 148 -173 425 -72 425 154 0
163 -160 280 -313 231z m156 -126 c55 -51 67 -104 37 -166 -25 -53 -66 -75
-132 -70 -47 3 -59 9 -88 40 -27 30 -33 45 -33 82 0 92 57 149 143 142 32 -3
56 -12 73 -28z"
        />
      </g>
    </svg>
  );
}
