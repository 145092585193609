// Dependencies
import React, { useState } from 'react';
import { MultiselectFilter, RangeSliderFilter, TextFilter } from '@components';
import {
  getAdrValues,
  getBrandValues,
  getExtrasValues,
  getGearboxValues,
  getKmsValues,
  getMmaValues,
  getPlateDateValues,
  getSubcategoryValues,
  getTypeValues,
  getUbicationValues,
  getAuxBrakeValues,
} from '@lib/filters';
import { prettyNumber } from '@lib/utils';
import { useCollapse } from 'react-collapsed';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import {
  formatPlatesDateToSliderValue,
  formatPlatesDateValueSliderToDate,
} from '@lib/filterVehicles';
import {
  IconEye,
  IconEyeOff,
  IconChevronUp,
  IconChevronDown,
  IconTrash,
} from '@tabler/icons-react';
import { useWindowSize } from '@uidotdev/usehooks';

export default function FiltersVehicles({
  vehicles,
  stateFilters,
  cleanFilterById,
  cleanFilters,
  applyFilter,
  isSegment,
}) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: expanded,
  });
  const { width: windowWidth } = useWindowSize();
  const collapseProps = windowWidth < 768 ? getCollapseProps() : {};

  const typeValues = getTypeValues(vehicles);
  const subcategoryValues = getSubcategoryValues(vehicles);
  const ubicationValues = getUbicationValues(vehicles);
  const extrasValues = getExtrasValues(vehicles);
  const brandValues = getBrandValues(vehicles);
  const gearboxValues = getGearboxValues(vehicles);
  const auxBrakeValues = getAuxBrakeValues(vehicles);
  const adrValues = getAdrValues(vehicles);
  const kmsValuesProps = getKmsValues(vehicles);
  const plateDateValuesProps = getPlateDateValues(vehicles);
  const mmaValuesProps = getMmaValues(vehicles);

  const actualFilters = Object.entries(stateFilters);

  const filtersLabelIntlId = {
    tipo: 'vehicle.tipo_de_vehiculo',
    subcategoria: 'vehicle.subcategoria',
    marca: 'vehicle.marca',
    modelo: 'vehicle.modelo',
    fecha_matriculacion: 'vehicle.fecha_de_matriculacion',
    matricula: 'vehicle.matricula',
    kms: 'vehicle.kms_segun_tacografo',
    ubicacion: 'vehicle.campa',
    adr: 'vehicle.adr',
    caja_cambios: 'vehicle.caja_de_cambios',
    mma: 'vehicle.pma',
    freno_auxiliar: 'vehicle.freno_auxiliar',
    extras: 'vehicle.extras',
  };

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg p-2 h-min max-w-full md:min-w-[280px] md:max-w-[280px]">
      <div className="font-medium text-xl my-2 flex items-center gap-2">
        <button
          type="button"
          className="block md:hidden w-full text-truckBlue"
          {...getToggleProps({
            onClick: () => setExpanded((expanded) => !expanded),
          })}
        >
          <div className="flex gap-2 items-center justify-between">
            <div className="flex gap-2 items-center">
              {expanded ? <IconEye /> : <IconEyeOff />}
              <span>
                <FormattedMessage id="misc_filter" />
              </span>
            </div>
            {expanded ? <IconChevronUp /> : <IconChevronDown />}
          </div>
        </button>
        <span className="hidden md:block">
          <FormattedMessage id="misc_filter" />
        </span>
      </div>
      <div className="flex gap-1 flex-wrap">
        {actualFilters.map(([id]) => {
          return (
            <button
              className="flex gap-1 items-center justify-between bg-transparent border-red-600 border focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-xs px-2 py-1 text-center text-red-600"
              type="button"
              key={id}
              onClick={() => cleanFilterById(id)}
            >
              <span className="text-red-600">
                {intl.formatMessage({ id: filtersLabelIntlId[id] }) || id}
              </span>
              <IconTrash className="w-5 h-5" />
            </button>
          );
        })}
      </div>
      {actualFilters.length ? (
        <button
          aria-label="Limpiar filtros"
          onClick={cleanFilters}
          type="button"
          className="mt-2 text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-2 py-1 text-center mr-2 mb-2"
        >
          <FormattedMessage id="misc_reset_filter" />
        </button>
      ) : null}

      <div {...collapseProps} className="flex flex-col">
        {isSegment ? (
          <div className="text-sm bg-orange-100 px-2 py-1 rounded-lg">
            <span>
              Actualmente está dentro de un tipo de vehículo, si desea filtrar
              por varios tipos de vehículo pulse{' '}
            </span>
            <Link className="font-bold" to="/vehiculos">
              aquí
            </Link>
          </div>
        ) : (
          <MultiselectFilter
            id="tipo"
            onApply={applyFilter}
            state={stateFilters}
            values={typeValues}
            label={intl.formatMessage({ id: filtersLabelIntlId['tipo'] })}
          />
        )}

        <MultiselectFilter
          id="subcategoria"
          onApply={applyFilter}
          state={stateFilters}
          values={subcategoryValues}
          label={intl.formatMessage({ id: filtersLabelIntlId['subcategoria'] })}
        />
        <MultiselectFilter
          id="marca"
          onApply={applyFilter}
          state={stateFilters}
          values={brandValues}
          label={intl.formatMessage({ id: filtersLabelIntlId['marca'] })}
        />
        <TextFilter
          id="modelo"
          onApply={applyFilter}
          state={stateFilters}
          label={intl.formatMessage({ id: filtersLabelIntlId['modelo'] })}
        />
        <RangeSliderFilter
          id="fecha_matriculacion"
          onApply={applyFilter}
          state={stateFilters}
          label={intl.formatMessage({
            id: filtersLabelIntlId['fecha_matriculacion'],
          })}
          parser={formatPlatesDateToSliderValue}
          formatter={formatPlatesDateValueSliderToDate}
          data={plateDateValuesProps}
        />
        <TextFilter
          id="matricula"
          onApply={applyFilter}
          state={stateFilters}
          label={intl.formatMessage({ id: filtersLabelIntlId['matricula'] })}
        />
        <RangeSliderFilter
          id="kms"
          onApply={applyFilter}
          state={stateFilters}
          label={intl.formatMessage({ id: filtersLabelIntlId['kms'] })}
          formatter={(value) => prettyNumber(Math.ceil(value))}
          data={kmsValuesProps}
        />
        <MultiselectFilter
          id="adr"
          onApply={applyFilter}
          state={stateFilters}
          values={adrValues}
          label={intl.formatMessage({ id: filtersLabelIntlId['adr'] })}
        />
        <MultiselectFilter
          id="caja_cambios"
          onApply={applyFilter}
          state={stateFilters}
          values={gearboxValues}
          label={intl.formatMessage({ id: filtersLabelIntlId['caja_cambios'] })}
        />
        <RangeSliderFilter
          id="mma"
          onApply={applyFilter}
          state={stateFilters}
          label={intl.formatMessage({ id: filtersLabelIntlId['mma'] })}
          formatter={(value) => prettyNumber(Math.ceil(value))}
          data={mmaValuesProps}
        />
        <MultiselectFilter
          id="freno_auxiliar"
          onApply={applyFilter}
          state={stateFilters}
          values={auxBrakeValues}
          label={intl.formatMessage({
            id: filtersLabelIntlId['freno_auxiliar'],
          })}
        />
        <MultiselectFilter
          id="extras"
          onApply={applyFilter}
          state={stateFilters}
          values={extrasValues}
          label={intl.formatMessage({ id: filtersLabelIntlId['extras'] })}
        />
        <MultiselectFilter
          id="ubicacion"
          onApply={applyFilter}
          state={stateFilters}
          values={ubicationValues}
          label={intl.formatMessage({ id: filtersLabelIntlId['ubicacion'] })}
        />
      </div>
    </div>
  );
}
