// Dependencies
import React, { useEffect } from 'react';
import { Header, Footer, FavoritesMenu } from '@components';
import classNames from 'classnames';
import useFavorites from '@context/FavoritesContext/useFavorites';
import useMobileMenu from '@context/MobileMenuContext/useMobileMenu';
import ModalPriceDrop from '@components/modalPriceDrop';

export default function Layout({ children }) {
  const { setIsOpen: setOpenFavorites } = useFavorites();
  const { isOpen: isOpenMobileMenu, setIsOpen: setOpenMobileMenu } =
    useMobileMenu();

  useEffect(() => {
    setOpenFavorites(false);
    setOpenMobileMenu(false);
  }, [setOpenFavorites, setOpenMobileMenu]);

  return (
    <div
      className={classNames('min-h-screen flex flex-col', {
        'h-screen overflow-hidden': isOpenMobileMenu,
      })}
    >
      <FavoritesMenu />
      <Header />
      <div className="flex-1 mb-20 flex flex-col">{children}</div>
      <Footer />
      <ModalPriceDrop />
    </div>
  );
}
