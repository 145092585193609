import { differenceInCalendarMonths } from 'date-fns';
import { GATSBY_FILTER_LOCATIONS_MIX_NEXT } from '@lib/env';

function getUniqueByField(data, field) {
  return data.filter(
    (value, index, self) =>
      self.map((item) => item[field]).indexOf(value[field]) === index
  );
}

export function getPlateDateValues(vehicles) {
  const MAX_STEPS = 100;
  const minDate = Math.min(
    ...vehicles.map(({ fecha_de_matriculacion }) =>
      new Date(fecha_de_matriculacion).getTime()
    )
  );
  const maxDate = new Date();
  const diffMonts = differenceInCalendarMonths(maxDate, minDate);
  const monthsStep = Math.ceil(diffMonts / MAX_STEPS);
  const finalSteps = Math.ceil(diffMonts / monthsStep) * monthsStep;

  return {
    min: (finalSteps + 1) * -1,
    max: -1,
    step: monthsStep,
  };
}

export function getKmsValues() {
  return { min: 0, max: 2000000, step: 50000 };
}

export function getMmaValues(vehicles) {
  const values = vehicles
    .map(({ pma }) => pma)
    .filter((pma, index, self) => pma && self.indexOf(pma) === index);

  const start = Math.min(...values);
  const end = Math.max(...values);

  const step = (end - start) / 100;
  return { min: start, max: end, step };
}

export function getTypeValues(vehicles) {
  return getUniqueByField(vehicles, 'tipo_de_vehiculo')
    .filter(({ tipo_de_vehiculo }) => tipo_de_vehiculo)
    .map(({ tipo_de_vehiculo }) => ({
      key: tipo_de_vehiculo,
      label:
        tipo_de_vehiculo.charAt(0).toUpperCase() +
        tipo_de_vehiculo.toLowerCase().slice(1),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getSubcategoryValues(vehicles) {
  return getUniqueByField(vehicles, 'subcategoria')
    .filter(({ subcategoria }) => subcategoria)
    .map(({ subcategoria }) => ({
      key: subcategoria,
      label: subcategoria,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getUbicationValues(vehicles) {
  // Mezcla entre En transito y Proxima entrada
  const mixLocationsMixNext = JSON.parse(GATSBY_FILTER_LOCATIONS_MIX_NEXT);

  return getUniqueByField(
    vehicles.map((item) => item.ubicacion),
    '_id'
  )
    .map((item) => ({
      key: item._id,
      label: item.nombre_ubicacion,
    }))
    .filter((item) => !mixLocationsMixNext.includes(item.key))
    .sort((a, b) => a.label.localeCompare(b.label))
    .concat({ key: 'next', label: 'En tránsito / Próxima entrada' });
}

export function getExtrasValues(vehicles) {
  return vehicles
    .flatMap((item) => item.extras)
    .filter((item, index, self) => item && self.indexOf(item) === index)
    .sort((a, b) => a.localeCompare(b))
    .map((item) => ({
      key: item,
      label: item,
    }));
}

export function getBrandValues(vehicles) {
  const customOrder = ['scania', 'renault', 'iveco', 'maxus'];
  return getUniqueByField(vehicles, 'marca')
    .filter(({ marca }) => marca)
    .map(({ marca }) => ({
      key: marca,
      label: marca.charAt(0).toUpperCase() + marca.toLowerCase().slice(1),
    }))
    .sort((a, b) => {
      const indexA = customOrder.indexOf(a.key);
      const indexB = customOrder.indexOf(b.key);

      if (indexA !== -1 && indexB !== -1) return indexA - indexB;
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      return a.label.localeCompare(b.label);
    });
}

export function getGearboxValues(vehicles) {
  return getUniqueByField(vehicles, 'caja_de_cambios')
    .filter(({ caja_de_cambios }) => caja_de_cambios)
    .map(({ caja_de_cambios }) => ({
      key: caja_de_cambios,
      label:
        caja_de_cambios.charAt(0).toUpperCase() +
        caja_de_cambios.toLowerCase().slice(1),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getAuxBrakeValues(vehicles) {
  return getUniqueByField(vehicles, 'freno_auxiliar')
    .filter(({ freno_auxiliar }) => freno_auxiliar)
    .map(({ freno_auxiliar }) => ({
      key: freno_auxiliar,
      label:
        freno_auxiliar.charAt(0).toUpperCase() +
        freno_auxiliar.toLowerCase().slice(1),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function getAdrValues(vehicles) {
  return getUniqueByField(vehicles, 'adr')
    .filter(({ adr }) => adr)
    .map(({ adr }) => ({
      key: adr,
      label: adr,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
