// Dependencies
import React, { useState } from 'react';
import MobileMenuContext from './Context';

export default function MobileMenuProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MobileMenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  );
}
