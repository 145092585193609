// Dependencies
import React, { useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { FavoritesMenuList } from '@components';
import { Link } from 'gatsby-plugin-intl';
import useFavorites from '@context/FavoritesContext/useFavorites';

export default function FavoritesMenu() {
  const contentRef = useRef(null);
  const { isOpen, setIsOpen, favorites, displayedFavorites } = useFavorites();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    function handler(ev) {
      const isClickOutside = !contentRef?.current?.contains(ev.target);
      if (isOpen && isClickOutside) handleClose();
      return;
    }
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [isOpen, handleClose]);

  return (
    <div
      className={classNames(
        'z-[300] left-0 top-0 w-full h-full bg-black transition-all duration-1000',
        isOpen ? 'fixed bg-opacity-50' : 'static bg-opacity-0'
      )}
    >
      <div
        ref={contentRef}
        className={classNames(
          'bg-gray-50 z-[100] border-l-[5px] border-l-trucksur-600 transition-all duration-1000 h-screen fixed overflow-scroll',
          isOpen
            ? 'right-0 w-[300px] md:w-[400px]'
            : '-right-[300px] w-[300px] md:-right-[400px] md:w-[400px]'
        )}
      >
        <div className="bg-trucksur-600 w-[300px] md:w-[400px] px-4 fixed z-[110] h-28 flex flex-col justify-center gap-2">
          <div className="w-full flex justify-between items-center">
            <div>
              <div className="text-2xl text-white font-medium">Favoritos</div>
              {favorites?.length ? (
                <div className="text-xs text-white">
                  Hay {displayedFavorites?.length} elementos
                </div>
              ) : null}
            </div>

            <button
              aria-label="Cerrar menú de favoritos"
              className="flex items-center justify-center"
              type="button"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                className="w-8 h-8 stroke-white"
              >
                <title>Cerrar</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {displayedFavorites?.length ? (
            <Link
              to="/favoritos"
              className="py-1 text-white font bg-white/30 flex items-center gap-2 justify-center rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Ver en página</title>
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12 4c4.29 0 7.863 2.429 10.665 7.154l.22 .379l.045 .1l.03 .083l.014 .055l.014 .082l.011 .1v.11l-.014 .111a.992 .992 0 0 1 -.026 .11l-.039 .108l-.036 .075l-.016 .03c-2.764 4.836 -6.3 7.38 -10.555 7.499l-.313 .004c-4.396 0 -8.037 -2.549 -10.868 -7.504a1 1 0 0 1 0 -.992c2.831 -4.955 6.472 -7.504 10.868 -7.504zm0 5a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
                  strokeWidth="0"
                  fill="currentColor"
                />
              </svg>
              Ver en página
            </Link>
          ) : null}
        </div>

        <div className="my-28 px-3 py-3">
          <FavoritesMenuList />
        </div>
      </div>
    </div>
  );
}
