// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/youtube';
import { LoadingSpinner } from '@components';
import classNames from 'classnames';

export default function VideoModal({ isOpen, handleClose, data }) {
  const [isReady, setIsReady] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    setIsReady(false);
  }, [isOpen]);

  useEffect(() => {
    function handler(ev) {
      const isClickOutside = !contentRef?.current?.contains(ev.target);
      if (isOpen && isClickOutside) handleClose();
      return;
    }
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [isOpen, handleClose]);

  return isOpen ? (
    <div
      className={classNames(
        'fixed z-[100] left-0 top-0 w-full h-full overflow-auto bg-black bg-opacity-50 flex items-center justify-center'
      )}
    >
      {/*content*/}
      <div
        ref={contentRef}
        className="w-full max-w-[640px] mx-1 overflow-hidden border-0 rounded-lg shadow-lg flex flex-col p-1.5 bg-white outline-none focus:outline-none"
      >
        {/*close button*/}
        <div className="flex justify-end py-1.5 ">
          <button
            aria-label="Cerrar modal"
            className="flex justify-center items-center"
            type="button"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              className="w-7 h-7 stroke-gray-500 hover:stroke-[3px] hover:stroke-gray-600 transition-all"
            >
              <title>Cerrar</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {/*body*/}
        <div className="relative flex-auto aspect-video">
          <div
            className={classNames(
              isReady ? 'hidden' : 'block',
              'flex flex-col gap-4 items-center justify-center w-full h-full'
            )}
          >
            <LoadingSpinner className="w-16 h-16 text-gray-200 animate-spin fill-gray-500 mr-2" />
            <span className="text-2xl">Cargando...</span>
          </div>
          <div
            className={classNames(
              isReady ? 'block' : 'hidden',
              'w-full h-full'
            )}
          >
            <ReactPlayer
              className="!w-full !h-full"
              onReady={() => setIsReady(true)}
              url={data.url}
              muted={true}
              controls={true}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
