// Dependencies
import React from 'react';

export default function LogoRentDelegations() {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 198.43 198.43"
    >
      <title>Rent Delegations</title>
      <g id="Grupo_8" data-name="Grupo 8">
        <g id="QGWQem">
          <g id="Grupo_5" data-name="Grupo 5">
            <path
              id="Trazado_30"
              data-name="Trazado 30"
              d="M122.55,43h4.38c.79.14,1.58.3,2.36.43a25,25,0,0,1,20.13,27,57.68,57.68,0,0,1-4.78,16.1,2.57,2.57,0,0,0-.06,1.73,48,48,0,0,1-.55,36.34c-6.91,16.3-19.25,26.23-36.61,29.84-2,.41-4,.59-5.93.87H95.12a4.58,4.58,0,0,0-.73-.18,46.28,46.28,0,0,1-25.23-9.36C52.71,133.15,46.16,116.38,49.9,96A47,47,0,0,1,70.29,65.13a48.13,48.13,0,0,1,31.42-8.55c.79.05,1.07-.28,1.41-.87A24.23,24.23,0,0,1,117,44.31C118.81,43.74,120.7,43.46,122.55,43ZM110.68,146.89c-.83-1.35-1.68-2.67-2.46-4a1.55,1.55,0,0,0-1.56-.88c-4.53,0-9.07,0-13.6,0a3.34,3.34,0,0,1-3.75-2.88,3.84,3.84,0,0,1,0-.83q0-9.48,0-19a3.32,3.32,0,0,1,3-3.65,3.05,3.05,0,0,1,.74,0h11.63c.31,0,.62,0,1-.06l-.65-.9c-1.68-2.25-3.38-4.49-5.05-6.77a3.28,3.28,0,0,1,.34-4.62l.2-.15c.68-.6,1.4-1.17,2.11-1.75l6.82-5.67c-.91-1.7-1.82-3.28-2.62-4.91-4.3-8.73-8-17.61-6.5-27.78A42.9,42.9,0,0,0,72,72l1.19.63,20.39,10.2a3.4,3.4,0,0,1,2.15,4.26c-1,5.2-2.08,10.38-3.11,15.58a4.14,4.14,0,0,1-1.89,2.81c-2.39,1.55-4.74,3.17-7.13,4.72a1.74,1.74,0,0,0-.91,1.68c0,4.32,0,8.63,0,13a3.49,3.49,0,0,1-2.82,4l-4.35,1.51-9.72,3.36a42.35,42.35,0,0,0,44.86,13.16Zm-49-18.72c4.58-1.58,9.08-3.16,13.61-4.68.75-.25.79-.7.79-1.32,0-4.2,0-8.41,0-12.62A3.79,3.79,0,0,1,78,106.07c2.52-1.62,5-3.33,7.49-5a2.3,2.3,0,0,0,1.09-1.64q1-5.27,2.11-10.52A1.2,1.2,0,0,0,88,87.39l-.12,0C81.07,84,74.26,80.54,67.44,77.13q-.33-.13-.66-.24a42.63,42.63,0,0,0-5.05,51.28Zm78.18-32.34c-.24.35-.45.64-.62.94A152.66,152.66,0,0,1,127.45,114a3.29,3.29,0,0,1-4.51,1.17,3.39,3.39,0,0,1-1.17-1.18c-2.78-3.81-5.47-7.67-8.2-11.53-.22-.31-.44-.64-.7-1L107,106.32c2.62,3.51,5.14,6.91,7.71,10.29a3.37,3.37,0,0,1-2.85,5.65c-4.9,0-9.8,0-14.7,0H96v13.16h1.24c3.87,0,7.75.05,11.62,0a4,4,0,0,1,3.87,2.17c1.37,2.32,2.84,4.59,4.26,6.87a42.62,42.62,0,0,0,23-48.61Zm-15.28,11.08c1.17-1.64,2.28-3.14,3.32-4.68A103.6,103.6,0,0,0,140.84,78.4a27.37,27.37,0,0,0,1.94-12.87,18.21,18.21,0,0,0-34.4-5.73,20.45,20.45,0,0,0-1.06,14.32,62.88,62.88,0,0,0,5.32,13.64,141.54,141.54,0,0,0,12,19.15Z"
            />
            <path
              id="Trazado_31"
              data-name="Trazado 31"
              d="M124.74,56.35A11.54,11.54,0,0,1,136.28,67.9v0a11.55,11.55,0,1,1-11.54-11.6Zm0,6.59a5,5,0,0,0-4.93,5,5,5,0,1,0,9.92-.09,4.91,4.91,0,0,0-4.94-4.89Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
