// Dependencies
import { GATSBY_API_ROOT } from '../lib/env';

export async function fetcher(url, config) {
  const response = await fetch(GATSBY_API_ROOT.concat(`/${url}`), {
    ...config,
    headers: {
      Accept: 'application/json',
      ...(config?.headers || {}),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData?.message || 'Se produjo un error en la petición');
  }
  return response.json();
}

export async function fetcherBlob(url, config) {
  const response = await fetch(GATSBY_API_ROOT.concat(`/${url}`), {
    ...config,
    headers: {
      ...(config?.headers || {}),
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData?.message || 'Se produjo un error en la petición');
  }
  return response.blob();
}
