// Dependencies
import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { DatePostLabel } from '@components';

function getMainPostContentHTML(postContent) {
  const index = postContent.indexOf('<!--more');
  if (index !== -1) {
    return { html: postContent.substring(0, index), isMoreTag: true };
  } else {
    return { html: postContent, isMoreTag: false };
  }
}

export default function PostsListItem({ data }) {
  const postDetailContent = getMainPostContentHTML(data.post_content);
  function postContent() {
    return {
      __html: postDetailContent.html,
    };
  }

  return (
    <div className="border-[1px] border-trucksur-600 rounded-md relative py-3 px-4">
      <DatePostLabel date={data.post_date} />
      <div className="grid grid-cols-1 gap-4 mt-4">
        <h2 className="font-bold text-truckBlue pb-2 text-2xl">
          {data.post_title}
        </h2>
        <div
          className="text-justify text-[#555555] leading-[1.5rem] childImg:min-w-full childImg:object-cover childImg:my-4 [&>p]:mb-6"
          dangerouslySetInnerHTML={postContent()}
        />

        <Link to={`/noticias/${data.post_name}`} className="font-bold text-xl">
          {postDetailContent.isMoreTag
            ? 'Leer mas...'
            : 'Acceder al artículo...'}
        </Link>
      </div>
    </div>
  );
}
