// Dependencies
import React from 'react';
import { GATSBY_ENVIROMENT } from '@lib/env';

export default function Seo({ locale, title, description, image, pathname }) {
  const isProduction = GATSBY_ENVIROMENT === 'production';
  const url = !isProduction
    ? `https://pre-public.trucksur.com${pathname}`
    : `https://trucksur.com${pathname}`;
  const domain = !isProduction ? 'pre-public.trucksur.com' : 'trucksur.com';

  return (
    <>
      <html lang={locale} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {!isProduction ? (
        <meta name="robots" content="noindex, nofollow, noimageindex" />
      ) : null}
      {/* Facebook Meta Tags*/}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* Twitter Meta Tags*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
      />
    </>
  );
}
