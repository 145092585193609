// Dependencies
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { buildTechnicalData } from '@lib/technicalData';
import { Card } from '@components';

export default function VehicleTechnical({ data }) {
  const intl = useIntl();

  const technicalData = buildTechnicalData(data, intl);

  return (
    <>
      <Card>
        <Card.Header className="font-bold">
          <FormattedMessage id="misc_specs" />
        </Card.Header>
        <Card.Body>
          {technicalData.map((item, index) => {
            if (item.type === 'divider') {
              return <hr key={index.toString()} className="my-1.5" />;
            } else if (item.type === 'field') {
              return (
                <div
                  className="grid grid-cols-2 gap-4 text-sm"
                  key={index.toString()}
                >
                  <div className="font-medium text-left sm:!text-right">
                    {item.label}
                  </div>
                  <div className="text-right sm:!text-left">{item.value}</div>
                </div>
              );
            } else {
              return null;
            }
          })}

          {data?.fields?.measureImage?.localFile ? (
            <div>
              <hr className="my-1.5" />
              <div className="flex justify-center">
                <GatsbyImage
                  className="object-cover"
                  image={
                    data.fields.measureImage.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="measurements image"
                />
              </div>
              <span className="text-[14px] italic">
                * <FormattedMessage id="vehicle.measures_text" />
              </span>
            </div>
          ) : null}
          <hr className="my-1.5" />
          <span className="text-[14px] italic">
            * <FormattedMessage id="vehicle.specs_text" />
          </span>
        </Card.Body>
      </Card>
    </>
  );
}
