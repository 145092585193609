// Dependencies
import React from 'react';

export default function CatRigid({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.58 44.98"
      {...props}
    >
      <title>rigid</title>
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="332.69"
        cy="329.91"
        r="4.85"
        transform="translate(-393.42 40.72) rotate(-45)"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="314.89"
        cy="329.91"
        r="4.85"
        transform="translate(-398.64 28.13) rotate(-45)"
      />
      <path
        fill="#272c5f"
        d="M285.71,291.72v35.07h22.65l.14-.26a7.23,7.23,0,0,1,12.78,0l.14.26h4.74l.14-.26a7.23,7.23,0,0,1,12.78,0l.14.26h6.38a.56.56,0,0,0,.57-.57v-34.5a.57.57,0,0,0-.57-.57H286.28a.58.58,0,0,0-.57.57"
        transform="translate(-257.59 -291.15)"
      />
      <polygon
        fill="#272c5f"
        points="20.9 35.64 30.43 35.64 30.43 29.1 19.12 29.1 20.9 35.64"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="270.33"
        cy="329.91"
        r="4.85"
        transform="translate(-411.69 -3.38) rotate(-45)"
      />
      <path
        fill="#272c5f"
        d="M263.42,295.39l-5.8,15.85a.64.64,0,0,0,0,.2v14.79a.56.56,0,0,0,.56.56h5.65l.15-.26a7.22,7.22,0,0,1,12.78,0l.14.26h5.77v-31.2a.57.57,0,0,0-.57-.57H264a.56.56,0,0,0-.53.37m15.89,15.95H261.18l4.53-13.17h13.6Z"
        transform="translate(-257.59 -291.15)"
      />
      <line
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="1.5px"
        x1="6.66"
        y1="17.49"
        x2="6.66"
        y2="21.5"
      />
    </svg>
  );
}
