// Dependencies
import React from 'react';

export default function CatLight({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 77.76 44.09"
      {...props}
    >
      <title>light</title>
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="277.16"
        cy="354.76"
        r="4.85"
        transform="translate(-429.22 87.13) rotate(-58.28)"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="320.66"
        cy="354.76"
        r="4.85"
        transform="translate(-408.59 124.13) rotate(-58.28)"
      />
      <path
        fill="#272c5f"
        d="M333.82,316.89H295.44a2.84,2.84,0,0,0-2.84,2.83v15.21h-2.82V317.46a.57.57,0,0,0-.57-.57h-7.67a8.49,8.49,0,0,0-6.65,3.21l-14.15,17.77a8.56,8.56,0,0,0-1.85,5.3v2.55a5.67,5.67,0,0,0,5.67,5.67h6.15l.07-.12a7.22,7.22,0,0,1,12.78,0l.07.12h30.58l.07-.12a7.22,7.22,0,0,1,12.78,0l.07.12h9.53V319.72a2.84,2.84,0,0,0-2.84-2.83m-47,18H266.92l10.32-13a5.47,5.47,0,0,1,4.3-2.08h5.24Z"
        transform="translate(-258.89 -316.89)"
      />
    </svg>
  );
}
