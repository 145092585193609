// Dependencies
import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { useIntl } from 'gatsby-plugin-intl';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import usePriceDrop from '@context/PriceDropContext/usePriceDrop';
import { fetchCreatePriceDrop } from '@api/operations';

const INITIAL_VALUES = {
  full_name: '',
  email: '',
  phone: '',
  accept_privacy: false,
  accept_newsletter: true,
};

export default function useModalPriceDrop() {
  const recaptchaRef = useRef();
  const { data, handleClose, isOpen } = usePriceDrop();
  const [fetching, setFetching] = useState(false);
  const intl = useIntl();

  const schema = yup.object({
    full_name: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'validation_email' }))
      .required(intl.formatMessage({ id: 'validation_required' })),
    phone: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    accept_privacy: yup
      .bool()
      .required(intl.formatMessage({ id: 'validation_conditions' }))
      .oneOf([true], intl.formatMessage({ id: 'validation_conditions' })),
    accept_newsletter: yup.bool(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    initialValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset(INITIAL_VALUES);
    }
  }, [isOpen, form]);

  const onSubmit = async (values) => {
    setFetching(true);
    try {
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      await fetchCreatePriceDrop({
        ...values,
        actual_price: data.price,
        vehicle_id: data.id,
        recaptchaToken,
      });

      toast('✅ Solicitud enviada correctamamente', {
        className: 'text-sm',
      });
      form.reset(INITIAL_VALUES);
      handleClose();
    } catch (err) {
      console.error(err);
      toast('❌ Se produjo un error, vuelva a intentarlo', {
        className: 'text-sm',
      });
    } finally {
      setFetching(false);
    }
  };

  return {
    form,
    fetching,
    onSubmit,
    recaptchaRef,
  };
}
