// Dependencies
import React, { useRef, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { VehiclesListItem } from '@components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { cn } from '@lib/utils';
import { useWindowSize } from '@uidotdev/usehooks';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode } from 'swiper/modules';

const BREAKPOINTS = {
  max: {
    slidesPerView: 1,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  1090: {
    slidesPerView: 4,
    spaceBetween: 15,
  },
};

function getBreakpointByWidth(width) {
  if (width >= 1090) return '1090';
  if (width >= 768) return '768';
  if (width >= 640) return '640';
  return 'max';
}

export default function VehiclesCardCarousel({
  data,
  proxEntImg,
  interest,
  className,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null);
  const swiperRef = useRef(null);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    const breakpoint = getBreakpointByWidth(windowWidth);
    setCurrentBreakpoint(breakpoint);
  }, [windowWidth, setCurrentBreakpoint]);

  const { isStart, isEnd } = useMemo(() => {
    const elements = data.length;
    const viewedElements = BREAKPOINTS[currentBreakpoint]?.slidesPerView;
    return {
      isEnd: activeIndex + viewedElements >= elements,
      isStart: activeIndex === 0,
    };
  }, [data, currentBreakpoint, activeIndex]);

  return (
    <div className={classNames('relative container mx-auto', className)}>
      {!isStart && (
        <button
          type="button"
          className="absolute h-full -left-0 flex items-center"
          onClick={() => {
            swiperRef.current?.slidePrev();
          }}
        >
          <IconChevronLeft
            strokeWidth={2}
            className="stroke-gray-400 h-12 w-12"
          />
        </button>
      )}
      {!isEnd && (
        <button
          type="button"
          className="absolute h-full -right-0 flex items-center"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <IconChevronRight
            strokeWidth={2}
            className="stroke-gray-400 h-12 w-12"
          />
        </button>
      )}
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          setCurrentBreakpoint(swiper.currentBreakpoint);
        }}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
        freeMode={true}
        pagination={false}
        modules={[FreeMode]}
        breakpoints={BREAKPOINTS}
        className={cn('swiper-vehicle py-4 mx-12', {
          'max-w-[270px] mx-auto': currentBreakpoint === 'max',
        })}
      >
        {data.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <VehiclesListItem
                data={item}
                proxEntImg={proxEntImg}
                interest={interest}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
