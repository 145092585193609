// Dependencies
import {
  subMonths,
  endOfMonth,
  startOfMonth,
  parse,
  format,
  isSameMonth,
  differenceInCalendarMonths,
} from 'date-fns';
import { toSimpleString } from './utils';
import { GATSBY_FILTER_LOCATIONS_MIX_NEXT } from './env';

export function validateSliderValue(value, min, max) {
  const isValidArray = Array.isArray(value) && value.length === 2;
  if (!isValidArray) return false;

  if (value[0] < min || value[0] > max || value[1] < min || value[1] > max) {
    return false;
  }

  return true;
}

export function formatPlatesDateValueSliderToDate(value) {
  const monthsToSub = value === -1 ? 0 : value * -1 - 1;
  const actualDate = new Date();
  const parseDate = subMonths(actualDate, monthsToSub);
  return format(parseDate, 'MM/yyyy');
}

export function formatPlatesDateToSliderValue(date) {
  const parseDate = parse(date, 'MM/yyyy', new Date());
  const isCurrentMonth = isSameMonth(parseDate, new Date());
  if (isCurrentMonth) {
    return -1;
  } else {
    const actualDate = new Date();
    const diff = differenceInCalendarMonths(actualDate, parseDate);
    return diff * -1 - 1;
  }
}

export function getVehiclesByFilters(vehicles, filters) {
  const filtersArray = Object.entries(filters);
  return vehicles.filter((vehicle) => {
    return filtersArray.every(([key, value]) => {
      //tipo
      if (key === 'tipo') {
        const valuesArray = value.split(',');
        return valuesArray.includes(vehicle.tipo_de_vehiculo);
      }
      //subcategoria
      if (key === 'subcategoria') {
        const valuesArray = value.split(',');
        return valuesArray.includes(vehicle.subcategoria);
      }
      //marca
      if (key === 'marca') {
        const valuesArray = value.split(',');
        return valuesArray.includes(vehicle.marca);
      }
      //modelo
      if (key === 'modelo') {
        return (
          toSimpleString(vehicle.modelo).indexOf(toSimpleString(value)) !== -1
        );
      }
      //fecha_matriculacion
      if (key === 'fecha_matriculacion') {
        const [min, max] = value.split(',');
        const dateEnd = endOfMonth(parse(max, 'MM/yyyy', new Date()));
        const dateInit = startOfMonth(parse(min, 'MM/yyyy', new Date()));
        if (!vehicle.fecha_de_matriculacion) return true;
        const vehicleDate = new Date(vehicle.fecha_de_matriculacion).getTime();
        return (
          vehicleDate >= dateInit.getTime() && vehicleDate <= dateEnd.getTime()
        );
      }
      //matricula
      if (key === 'matricula') {
        return (
          toSimpleString(vehicle.matricula).indexOf(toSimpleString(value)) !==
          -1
        );
      }
      //kms
      if (key === 'kms') {
        const valuesArray = value.split(',');
        const min = parseInt(valuesArray[0]);
        const max = parseInt(valuesArray[1]);
        return (
          vehicle.kms_segun_tacografo >= min &&
          vehicle.kms_segun_tacografo <= max
        );
      }
      //ubicacion
      if (key === 'ubicacion') {
        let valuesArray = value.split(',');
        // Mezcla entre En transito y Proxima entrada
        const mixLocationsMixNext = JSON.parse(
          GATSBY_FILTER_LOCATIONS_MIX_NEXT
        );
        // Si se selecciona la mezcla de En transito y Proxima entrada
        if (valuesArray.includes('next')) {
          valuesArray = [
            ...valuesArray.filter((v) => v !== 'next'),
            ...mixLocationsMixNext,
          ];
        }
        return valuesArray.includes(vehicle.ubicacion['_id']);
      }
      //adr
      if (key === 'adr') {
        const valuesArray = value.split(',');
        return valuesArray.includes(vehicle.adr);
      }
      //caja_cambios
      if (key === 'caja_cambios') {
        const valuesArray = value.split(',');
        return valuesArray.includes(vehicle.caja_de_cambios);
      }
      //mma
      if (key === 'mma') {
        const valuesArray = value.split(',');
        if (!vehicle.pma) return true;
        const min = parseInt(valuesArray[0]);
        const max = parseInt(valuesArray[1]);
        return vehicle.pma >= min && vehicle.pma <= max;
      }
      //freno_auxiliar
      if (key === 'freno_auxiliar') {
        const valuesArray = value.split(',');
        return valuesArray.includes(vehicle.freno_auxiliar);
      }
      //extras
      if (key === 'extras') {
        const valuesArray = value.split(',');
        return valuesArray.every((extra) => vehicle.extras.includes(extra));
      }
      if (key === 'financ') {
        const MARGIN = 5000;
        const valuesArray = value.split(',').map((v) => Number(v));
        const aproximatedPrice = valuesArray[3];
        const min = aproximatedPrice - MARGIN;
        const max = aproximatedPrice + MARGIN;
        return vehicle.precio >= min && vehicle.precio <= max;
      }
      return true;
    });
  });
}
