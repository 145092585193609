// Dependencies
import { useState, useRef, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import { useIntl } from 'gatsby-plugin-intl';
import { getCountriesByLanguage } from '@lib/utils';
import { graphql, useStaticQuery } from 'gatsby';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchCreateBuyRequest } from '@api/operations';

const query = graphql`
  query {
    allCountry {
      edges {
        node {
          id
          name
          alpha2Code
          translations {
            es
            en
            ca
            fr
            pt
          }
        }
      }
    }
    allProvinceEs {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function useBuyForm() {
  const { allCountry, allProvinceEs } = useStaticQuery(query);
  const recaptchaRef = useRef();
  const [fetching, setFetching] = useState(false);
  const intl = useIntl();

  const schema = yup.object({
    full_name: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'validation_email' }))
      .required(intl.formatMessage({ id: 'validation_required' })),
    country: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    province: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    phone: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    accept_privacy: yup
      .bool()
      .required(intl.formatMessage({ id: 'validation_conditions' }))
      .oneOf([true], intl.formatMessage({ id: 'validation_conditions' })),
    accept_newsletter: yup.bool(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    initialValues: {
      full_name: '',
      email: '',
      country: '',
      phone: '',
      brand: '',
      model: '',
      date_matriculation: '',
      accept_phone_call: false,
      accept_privacy: false,
      accept_newsletter: true,
    },
  });

  const countryValue = form.watch('country');

  useEffect(() => {
    if (countryValue) {
      form.setValue('province', countryValue !== 'ES' ? 'OTRAS' : '');
    }
  }, [form, countryValue]);

  const countriesData = allCountry.edges.map(({ node }) => ({ ...node }));

  const countries = useMemo(() => {
    return getCountriesByLanguage(intl.locale, countriesData).sort((a, b) =>
      a.key === 'ES' ? -1 : b.key === 'ES' ? 1 : a.label.localeCompare(b.label)
    );
  }, [intl, countriesData]);

  const countryValues = [
    {
      value: '',
      hidden: true,
      label: intl.formatMessage({ id: 'misc_select_opcion' }),
    },
    ...countries.map(({ key, label }) => ({
      value: key,
      label,
    })),
  ];

  const provincesData = allProvinceEs.edges.map(({ node }) => ({ ...node }));
  const provinceValues = useMemo(() => {
    return countryValue !== 'ES'
      ? [
          {
            value: '',
            hidden: true,
            label: intl.formatMessage({ id: 'misc_select_opcion' }),
          },
          { value: 'OTRAS', label: 'Otras' },
        ]
      : [
          {
            value: '',
            hidden: true,
            label: intl.formatMessage({ id: 'misc_select_opcion' }),
          },
          ...provincesData.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        ];
  }, [intl, provincesData, countryValue]);

  const onSubmit = async (values) => {
    setFetching(true);
    try {
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      await fetchCreateBuyRequest({ ...values, recaptchaToken });
      toast('✅ Solicitud enviada correctamamente', {
        className: 'text-sm',
      });
      form.reset();
    } catch (err) {
      console.error(err);
      toast('❌ Se produjo un error, vuelva a intentarlo', {
        className: 'text-sm',
      });
    } finally {
      setFetching(false);
    }
  };

  return {
    form,
    fetching,
    countryValues,
    provinceValues,
    onSubmit,
    recaptchaRef,
  };
}
