// Dependencies
import React from 'react';
import format from 'date-fns/format';

export default function DatePostLabel({ date }) {
  return (
    <div className="absolute py-[3px] px-[6px] top-[-15px] rounded-md bg-truckBlue text-[0.9rem] text-white">
      {format(new Date(date), 'dd/MM/yyyy')}
    </div>
  );
}
