// Dependencies
import React from 'react';
import { LoadingSpinner } from '@components';
import classnames from 'classnames';

export default function Button({
  children,
  isDisabled,
  isLoading,
  color = 'blue',
  className = '',
  light = false,
  type = 'button',
  ...props
}) {
  return (
    <button
      disabled={isDisabled}
      className={classnames(
        'px-auto w-fit h-fit text-white focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-colors',
        `bg-${color}-${light ? 500 : 600}`,
        {
          'bg-gray-500': isDisabled,
        },
        {
          [`bg-${color}-700`]: isLoading,
        },
        {
          [`hover:bg-${color}-${light ? 600 : 700} focus:ring-0`]: !isDisabled,
        },
        className
      )}
      type={type}
      {...props}
    >
      {isLoading ? (
        <div className="flex w-fit mx-auto">
          <LoadingSpinner className="w-5 h-5 text-gray-200 animate-spin fill-gray-500 mr-2" />
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
}
