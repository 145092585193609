// Dependencies
import React from 'react';
import { LogoPercentage } from '@components';
import { Link } from 'gatsby-plugin-intl';
import classNames from 'classnames';

export default function BannerFinancial({ className }) {
  return (
    <div
      className={classNames(
        'w-full bg-gradient-to-t from-gray-100 to-gray-300 rounded-xl',
        className
      )}
    >
      <div className="grid grid-cols-[min-content_1fr] md:grid-cols-[min-content_minmax(0,_600px)_min-content] justify-between text-truckBlue py-4 px-5 items-center gap-5">
        <LogoPercentage className="w-20 h-20 fill-gray-400" />

        <div className="grid grid-cols-1 justify-items-center gap-3">
          <p className="text-2xl lg:text-3xl font-bold text-center">
            SIMULA TU FINANCIACIÓN
          </p>
          <p className="text-md lg:text-lg font-light text-center">
            En Trucksur contamos con nuestra calculadora financiera donde podrás
            elegir el importe, el plazo de devolución, calcular la cuota de la
            financiación y conocer los intereses
          </p>
          <Link
            to="/calculadora-financiera"
            className="text-lg py-1 px-3 text-white bg-trucksur-600 hover:bg-trucksur-700 hover:text-white hover:no-underline transition-all"
          >
            CALCULADORA
          </Link>
        </div>

        <LogoPercentage className="hidden md:block w-20 h-20 fill-gray-400" />
      </div>
    </div>
  );
}
