// Dependencies
import React from 'react';
import classNames from 'classnames';
import useEmptyVehicles from './useEmptyVehicles';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Recaptcha, Button, InputTextForm, InputCheckbox } from '@components';
import PrivacityCheckbox from '@components/privacityCheckbox';
import { LuSend } from 'react-icons/lu';

export default function EmptyVehicles({ className, resetFilters, isFiltered }) {
  const intl = useIntl();

  const { form, fetching, onSubmit, recaptchaRef } = useEmptyVehicles();
  const errors = form.formState.errors;

  return (
    <div
      className={classNames(
        'w-full h-fit flex flex-col gap-3 items-center justify-center p-6 border border-gray-500 rounded-xl shadow-md',
        className
      )}
    >
      <div className="w-full flex gap-2 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="currentColor"
          className="w-12 h-12 stroke-red-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
          />
        </svg>
        <span className="text-xl text-red-600">
          Actualmente no hay vehículos para mostrar
        </span>
      </div>

      <p className="font-light">
        Actualmente no hay vehículos que coincidan con sus criterios de
        búsqueda. Si desea recibir un correo electrónico cuando haya vehículos
        que coincidan con sus criterios de búsqueda, por favor, rellene el
        siguiente formulario:
      </p>
      <hr />
      <div>
        <form
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-2"
        >
          <InputTextForm
            isRequired
            id="full_name"
            label={intl.formatMessage({
              id: 'misc_full_name',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_full_name',
            })}
            errors={errors}
            {...form.register('full_name')}
          />
          <InputTextForm
            isRequired
            type="tel"
            id="phone"
            label={intl.formatMessage({
              id: 'misc_phone',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_phone',
            })}
            errors={errors}
            {...form.register('phone')}
          />
          <InputTextForm
            isRequired
            type="email"
            id="email"
            label={intl.formatMessage({
              id: 'misc_email',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_email',
            })}
            errors={errors}
            {...form.register('email')}
          />
          <PrivacityCheckbox form={form} />
          <InputCheckbox
            defaultChecked={true}
            {...form.register('accept_newsletter')}
            id="accept_newsletter"
            label={intl.formatMessage({
              id: 'misc_accept_newsletter',
            })}
            errors={errors}
          />
          <hr className="my-1" />
          <div className="flex justify-between gap-3">
            {isFiltered && resetFilters ? (
              <Button
                className="bg-red-700 hover:bg-red-800"
                onClick={resetFilters}
              >
                Pulse aquí para resetear el filtro
              </Button>
            ) : null}
            <Button color="trucksur" type="submit" isLoading={fetching}>
              <div className="flex w-fit mx-auto">
                <LuSend className="h-5 w-5" />
                <div className="ml-[5px]">
                  <FormattedMessage id="misc_send" />
                </div>
              </div>
            </Button>
          </div>
        </form>
        <div className="hidden">
          <Recaptcha ref={recaptchaRef} />
        </div>
      </div>
    </div>
  );
}
