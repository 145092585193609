// Dependencies
import { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { calcMonthly, toMiddleValue } from '@lib/financial';
import {
  prettyNumber,
  getArrayRangeEntrada,
  getArrayRangeMonths,
} from '@lib/utils';
import { useIntl, navigate } from 'gatsby-plugin-intl';
import differenceInMonths from 'date-fns/differenceInMonths';

const MONTHS = [12, 18, 24, 30, 36, 42, 48, 60, 72];

export default function useVehicleFinancial({ dataVehicle, dataFinancial }) {
  const intl = useIntl();
  const fim = intl.formatMessage;

  const entradaValues = getArrayRangeEntrada(dataVehicle.precio);
  const plazoValues = getArrayRangeMonths(
    MONTHS,
    168,
    dataVehicle.fecha_de_matriculacion
  );

  const { watch, control } = useForm({
    defaultValues: {
      entrada: toMiddleValue(entradaValues),
      plazo: toMiddleValue(plazoValues),
    },
  });

  const entradaValue = watch('entrada');
  const plazoValue = watch('plazo');

  const defaultCuota = calcMonthly({
    amount: dataVehicle.financ_pvp,
    entry: dataVehicle.financ_entrada,
    months: dataVehicle.financ_plazo,
    residualValue: dataVehicle.financ_valor_res,
    interest: dataFinancial.interest,
  });

  const calcCuota = useCallback(() => {
    return calcMonthly({
      amount: dataVehicle.precio,
      entry: entradaValues[entradaValue],
      months: plazoValues[plazoValue],
      interest: dataFinancial.interest,
    });
  }, [
    dataVehicle,
    dataFinancial,
    entradaValues,
    entradaValue,
    plazoValues,
    plazoValue,
  ]);

  const cuota = useMemo(() => calcCuota(), [calcCuota]);

  function handleSubmit() {
    const queryString = new URLSearchParams({
      brand: dataVehicle.marca,
      type: dataVehicle.tipo_de_vehiculo,
      price_since: dataVehicle.precio - 5000,
      price_until: dataVehicle.precio + 5000,
      exclude: dataVehicle.id,
    }).toString();
    navigate(`/vehiculos/?${queryString}`);
  }

  const isAlertPlazo = useMemo(() => {
    const monthsAntiquity =
      MONTHS[plazoValue] +
      differenceInMonths(
        new Date(),
        new Date(dataVehicle.fecha_de_matriculacion)
      );
    return monthsAntiquity >= 132;
  }, [plazoValue, dataVehicle]);

  const values = useMemo(() => {
    const entradaLabel = fim({ id: 'financing.entry' })
      .concat(': ')
      .concat(prettyNumber(entradaValues[entradaValue], 0, '€'))
      .concat(' + ')
      .concat(fim({ id: 'misc_iva' }));

    const plazoLabel = fim({ id: 'financing.period' })
      .concat(': ')
      .concat(plazoValues[plazoValue])
      .concat(' ')
      .concat(fim({ id: 'misc_months' }));

    const cuotaLabel = fim({ id: 'financing.fee' })
      .concat(': ')
      .concat(prettyNumber(cuota, 0, fim({ id: 'financing.euro_month' })))
      .concat(' + ')
      .concat(fim({ id: 'misc_iva' }));

    return {
      entrada: entradaLabel,
      plazo: plazoLabel,
      cuota: cuotaLabel,
    };
  }, [fim, plazoValues, entradaValues, entradaValue, plazoValue, cuota]);

  const defValues = useMemo(() => {
    if (dataVehicle.es_financiable) {
      return {
        entrada: prettyNumber(dataVehicle.financ_entrada, 0, '€')
          .concat(' + ')
          .concat(fim({ id: 'misc_iva' })),
        plazo: dataVehicle.financ_plazo
          .toString()
          .concat(' ')
          .concat(fim({ id: 'misc_months' })),
        cuota: prettyNumber(
          defaultCuota,
          0,
          fim({ id: 'financing.euro_month' })
        )
          .concat(' + ')
          .concat(fim({ id: 'misc_iva' })),
        condiciones: dataVehicle.financ_condiciones,
      };
    }
    return null;
  }, [fim, dataVehicle, defaultCuota]);

  return {
    values,
    defValues,
    entradaValues,
    plazoValues,
    isAlertPlazo,
    control,
    handleSubmit,
  };
}
