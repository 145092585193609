// Dependencies
import { useState, useRef } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'gatsby-plugin-intl';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { fetchCreateContactRequest } from '@api/operations';

export default function useContactForm() {
  const recaptchaRef = useRef();
  const [fetching, setFetching] = useState(false);
  const intl = useIntl();
  const schema = yup.object({
    full_name: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'validation_email' }))
      .required(intl.formatMessage({ id: 'validation_required' })),
    phone: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    message: yup
      .string()
      .required(intl.formatMessage({ id: 'validation_required' })),
    accept_privacy: yup
      .bool()
      .required(intl.formatMessage({ id: 'validation_conditions' }))
      .oneOf([true], intl.formatMessage({ id: 'validation_conditions' })),
    accept_newsletter: yup.bool(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      accept_privacy: false,
      accept_newsletter: true,
    },
  });

  async function onSubmit(values) {
    setFetching(true);
    try {
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      await fetchCreateContactRequest({ ...values, recaptchaToken });
      toast('✅ Contacto enviado correctamamente', {
        className: 'text-sm',
      });
      form.reset();
    } catch (err) {
      console.error(err);
      toast('❌ Se produjo un error, vuelva a intentarlo', {
        className: 'text-sm',
      });
    } finally {
      setFetching(false);
    }
  }

  return {
    form,
    onSubmit,
    fetching,
    recaptchaRef,
  };
}
