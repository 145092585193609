// Dependencies
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';

const InputTextAreaForm = React.forwardRef(
  ({ className, isRequired, label, errors, ...props }, ref) => {
    const { id, name } = props;
    const textLabel = isRequired ? `* ${label}` : label;
    return (
      <div className={className}>
        {label ? (
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor={id}
          >
            {textLabel}
          </label>
        ) : (
          false
        )}
        <textarea
          ref={ref}
          className="text-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-400"
          {...props}
        />
        {errors && (
          <div className="text-red-600 text-sm mt-1.5 font-light">
            <ErrorMessage name={name} errors={errors} />
          </div>
        )}
      </div>
    );
  }
);

export default InputTextAreaForm;
