// Dependencies
import React from 'react';
import { FiPhoneCall } from 'react-icons/fi';

export default function StaticButtonPhone({ phone }) {
  return (
    <a
      className="my-[90px] mx-[20px] fixed bottom-[0px] right-[0px] h-[50px] w-[50px] rounded-[50px] bg-truckBlue z-[999] text-white flex justify-center items-center md:hidden"
      href={`tel:${phone}`}
      alt="Llamar por teléfono"
    >
      <FiPhoneCall size="1.6rem" color="#fff" />
    </a>
  );
}
