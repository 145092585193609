// Dependencies
/* global google */
import React, { useState } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from '@react-google-maps/api';
import pin from '@images/pin-trucksur.png';
import { InfoMapBallon } from '@components';
import { GATSBY_GOOGLE_MAPS_KEY } from '@lib/env';

export default function WhereMap({ data }) {
  const [places, setPlaces] = useState(data);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GATSBY_GOOGLE_MAPS_KEY,
  });

  const onLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    places
      ?.filter((place) => place.latitude && place.longitude)
      .forEach(({ latitude, longitude }) =>
        bounds.extend({ lat: latitude, lng: longitude })
      );
    map.fitBounds(bounds);
  };

  function handleOpenInfoWindow(id) {
    setPlaces((places) =>
      places.map((place) => ({ ...place, show: place.id === id }))
    );
  }

  function handleCloseInfoWindows() {
    setPlaces((places) => places.map((place) => ({ ...place, show: false })));
  }

  return (
    <div className="h-[60rem] w-full ">
      {!isLoaded ? (
        <span className="text-xl text-gray-500">Cargando el mapa...</span>
      ) : (
        <GoogleMap
          mapContainerClassName="h-full"
          onLoad={onLoad}
          onClick={handleCloseInfoWindows}
          options={{ streetViewControl: false, disableDefaultUI: true }}
        >
          {places
            .filter((place) => place.latitude && place.longitude)
            .map((place) => {
              return (
                <Marker
                  key={place.id}
                  position={{ lat: place.latitude, lng: place.longitude }}
                  icon={pin}
                  onClick={() => handleOpenInfoWindow(place.id)}
                >
                  {place.show ? (
                    <InfoWindow
                      data={place}
                      onCloseClick={handleCloseInfoWindows}
                    >
                      <InfoMapBallon data={place} />
                    </InfoWindow>
                  ) : (
                    false
                  )}
                </Marker>
              );
            })}
        </GoogleMap>
      )}
    </div>
  );
}
