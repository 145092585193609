// Dependencies
import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import InputCheckbox from '@components/inputCheckbox';

export default function PrivacityCheckbox({ form, ...rest }) {
  const intl = useIntl();
  const errors = form.formState.errors;

  const acceptPrivacityLabel = intl.formatMessage(
    { id: 'misc_accept_privacy' },
    {
      misc_privacy: (
        <Link to="/politica-privacidad/">
          {intl.formatMessage({ id: 'misc_privacy' })}
        </Link>
      ),
      misc_legal: (
        <Link to="/politica-legal/">
          {intl.formatMessage({ id: 'misc_legal' })}
        </Link>
      ),
    }
  );

  return (
    <InputCheckbox
      {...form.register('accept_privacy')}
      id="accept_privacy"
      label={acceptPrivacityLabel}
      errors={errors}
      {...rest}
    />
  );
}
