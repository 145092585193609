// Dependencies
import React from 'react';

export default function CatSemitrailer({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.64 47.34"
      {...props}
    >
      <title>semitrailer</title>
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="328.45"
        cy="439.07"
        r="4.85"
        transform="translate(-472.81 60.98) rotate(-55.28)"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="310.65"
        cy="439.07"
        r="4.85"
        transform="translate(-480.47 46.35) rotate(-55.28)"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="292.92"
        cy="439.07"
        r="4.85"
        transform="translate(-488.1 31.78) rotate(-55.28)"
      />
      <path
        fill="#272c5f"
        d="M253.29,398v38h33.1l.14-.26a7.23,7.23,0,0,1,12.78,0l.14.26h4.67l.14-.26a7.23,7.23,0,0,1,12.78,0l.14.26h4.74l.14-.26a7.23,7.23,0,0,1,12.78,0l.14.26h6.95V398Z"
        transform="translate(-253.29 -397.96)"
      />
    </svg>
  );
}
