// Dependencies
import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { Menu, LogoTrucksur, HeaderButtons } from '@components';
import classNames from 'classnames';
import useFavorites from '@context/FavoritesContext/useFavorites';
import useMobileMenu from '@context/MobileMenuContext/useMobileMenu';

function RespMenuButton({ isOpen, handleOpen, handleClose }) {
  return (
    <button
      className="h-full p-2 rounded-lg bg-transparent focus:outline-none"
      type="button"
      onClick={isOpen ? handleClose : handleOpen}
    >
      {!isOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth="2"
          className="w-10 h-10 stroke-white"
        >
          <title>Menu</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth="2"
          className="w-10 h-10 stroke-white"
        >
          <title>Cerrar</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      )}
    </button>
  );
}

function FavoriteButton({ handleOpen, handleClose }) {
  const { isOpen, favorites } = useFavorites();
  return (
    <button
      className="relative h-full p-2 rounded-lg bg-transparent focus:outline-none"
      type="button"
      onClick={isOpen ? handleClose : handleOpen}
    >
      {favorites.length ? (
        <div className="absolute w-3 h-3 bg-red-600 z-[90] rounded-full top-[22px] right-[6px]" />
      ) : null}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        className="w-8 h-8 stroke-white"
      >
        <title>Favoritos</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
      </svg>
    </button>
  );
}

export default function Header() {
  const { setIsOpen: setOpenFavorites } = useFavorites();
  const { setIsOpen: setOpenMobileMenu, isOpen: isOpenMobileMenu } =
    useMobileMenu();

  function handleOpenFavorites() {
    setOpenMobileMenu(false);
    setOpenFavorites(true);
  }

  function handleCloseFavorites() {
    setOpenFavorites(false);
  }

  function handleOpenMenu() {
    setOpenFavorites(false);
    setOpenMobileMenu(true);
  }

  function handleCloseMenu() {
    setOpenMobileMenu(false);
  }

  return (
    <header className="relative bg-truckBlue">
      {/* Header Mobile */}
      <div
        className={classNames(
          'bg-truckBlue w-screen z-[999999] h-full block md:hidden',
          isOpenMobileMenu ? 'fixed overflow-hidden' : 'static'
          // isOpenFavorites ? "fixed overflow-hidden" : "static",
        )}
      >
        <div className="container mx-auto px-3 bg-truckBlue">
          <div className="grid grid-cols-[1fr_auto_auto] h-20 items-center">
            <div className="w-full max-w-[150px]">
              <Link to="/">
                <LogoTrucksur className="fill-white" />
              </Link>
            </div>
            <FavoriteButton
              handleClose={handleCloseFavorites}
              handleOpen={handleOpenFavorites}
            />
            <RespMenuButton
              isOpen={isOpenMobileMenu}
              handleOpen={handleOpenMenu}
              handleClose={handleCloseMenu}
            />
          </div>
          {isOpenMobileMenu && (
            <div>
              <Menu className="mt-2" />
              <HeaderButtons className="mt-2" />
            </div>
          )}
        </div>
      </div>
      {/* Header Desktop */}
      <div className="container mx-auto px-3 hidden md:block">
        <div className="grid grid-cols-[1fr_auto] h-24 items-center bg-truckBlue">
          <div className="w-full max-w-[200px]">
            <Link to="/">
              <LogoTrucksur className="fill-white" />
            </Link>
          </div>
          <HeaderButtons />
        </div>
        <Menu className="mt-2" />
      </div>
    </header>
  );
}
