const menu = [
  { label_id: 'menu_about', href: '/conocenos/' },
  { label_id: 'menu_vehicles', href: '/vehiculos/' },
  { label_id: 'menu_rent', href: '/alquiler/' },
  { label_id: 'menu_calculator', href: '/calculadora-financiera/' },
  { label_id: 'menu_contact', href: '/contacto/' },
  { label_id: 'menu_news', href: '/noticias/' },
];

export default menu;
