// Dependencies
import React from 'react';

export default function CatConstruction({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74.17 54.74"
      {...props}
    >
      <title>construction</title>
      <line
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        x1="68.74"
        y1="6.65"
        x2="68.74"
        y2="13.99"
      />
      <path
        fill="#272c5f"
        d="M327,410.19a2.38,2.38,0,1,1,2.38,2.38,2.38,2.38,0,0,1-2.38-2.38"
        transform="translate(-260.65 -394.04)"
      />
      <path
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        d="M327,410.19a2.38,2.38,0,1,1,2.38,2.38A2.38,2.38,0,0,1,327,410.19Z"
        transform="translate(-260.65 -394.04)"
      />
      <path
        fill="none"
        stroke="#272c5f"
        strokeLinejoin="round"
        d="M327,416.54a2.38,2.38,0,1,0,2.38-2.39v-1.38"
        transform="translate(-260.65 -394.04)"
      />
      <circle
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        cx="321.34"
        cy="442.54"
        r="4.85"
        transform="translate(-486.08 60.56) rotate(-55.28)"
      />
      <path
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="2.74px"
        d="M270.45,442.54a4.86,4.86,0,1,1,4.85,4.86A4.85,4.85,0,0,1,270.45,442.54Z"
        transform="translate(-260.65 -394.04)"
      />
      <polyline
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="1.5px"
        points="2.58 33.21 0.75 33.21 0.75 30.34"
      />
      <path
        fill="#272c5f"
        d="M293.93,427.36v5.44h-1.48V415l32.82-15.09,2,2.29a.57.57,0,0,0,.75.1l3-2a.55.55,0,0,0,.12-.83l-3.6-4.24a3.33,3.33,0,0,0-3.93-.87L287.7,410.82a3.33,3.33,0,0,0-1.94,3V432.8h-1.49V417.6a.56.56,0,0,0-.56-.56H268.85a.57.57,0,0,0-.48.25l-5.6,8.52v13.05a.56.56,0,0,0,.56.57h5.38l.14-.27a7.23,7.23,0,0,1,12.78,0l.14.27h33l.14-.27a7.23,7.23,0,0,1,12.78,0l.14.27h6.38a.56.56,0,0,0,.57-.57v-11.5a.56.56,0,0,0-.57-.57H294.5a.57.57,0,0,0-.57.57m-15.61-3.12c-1.56.28-6.66,1.6-7,6.8h-5.66v-4.45l4.42-6.85h8.21Z"
        transform="translate(-260.65 -394.04)"
      />
      <line
        fill="none"
        stroke="#272c5f"
        strokeMiterlimit="10"
        strokeWidth="1.5px"
        x1="6.65"
        y1="34.56"
        x2="6.65"
        y2="38.57"
      />
    </svg>
  );
}
