// Dependencies
import React from 'react';

export default function CatBus({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.3 45" {...props}>
      <title>bus</title>
      <circle
        fill="none"
        stroke="#272C5F"
        strokeWidth="2.74px"
        strokeMiterlimit="10"
        cx="66.8"
        cy="38.7"
        r="4.9"
      />
      <circle
        fill="none"
        stroke="#272C5F"
        strokeWidth="2.74px"
        strokeMiterlimit="10"
        cx="17.1"
        cy="38.7"
        r="4.9"
      />
      <path
        fill="#272C5F"
        d="M76.1,0H10.7C8.4,0,6.3,1.5,5.4,3.6l-3.7,9.6c-1.1,2.9-1.7,6-1.7,9.1v9c0,2.3,1.9,4.2,4.2,4.2c0,0,0,0,0,0h6.3
	l0.1-0.3c1.9-3.5,6.2-4.9,9.8-3c1.3,0.7,2.3,1.7,3,3l0.1,0.3h36.7l0.1-0.3c1.9-3.5,6.2-4.9,9.8-3c1.3,0.7,2.3,1.7,3,3l0.1,0.3h2.7
	c2.3,0,4.2-1.9,4.2-4.2l0,0V4.2C80.3,1.9,78.4,0,76.1,0 M22.4,17.8H7c-1.5,0-2.7-1.2-2.7-2.6c0-0.3,0-0.6,0.2-0.9l0,0l3.7-9.6
	C8.6,3.7,9.5,3,10.6,3h11.7L22.4,17.8z M40.9,17.8H24.4V3h16.6L40.9,17.8z M58.5,17.8H42.9V3h15.6L58.5,17.8z M77.3,15.2
	c0,1.5-1.2,2.6-2.6,2.6H60.5V3h14.2c1.5,0,2.6,1.2,2.6,2.6L77.3,15.2z"
      />
      <line
        fill="none"
        stroke="#272C5F"
        strokeWidth="1.5px"
        strokeMiterlimit="10"
        x1="7.7"
        y1="15.2"
        x2="7.7"
        y2="19.2"
      />
    </svg>
  );
}
