// Dependencies
import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { formatDate } from '@lib/utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useFavorites from '@context/FavoritesContext/useFavorites';

export default function FavoritesMenuItem({ data }) {
  const { alternateFavorite } = useFavorites();

  return (
    <li className="rounded-md text-xs bg-white shadow-sm overflow-hidden">
      <Link
        to={`/vehiculo/${data.seo_name}`}
        className="hover:no-underline text-gray-800 hover:text-gray-800"
      >
        <div className="flex justify-between gap-1">
          <div className="aspect-[640/480] h-[100px] w-[80px] md:w-[100px] overflow-hidden">
            <GatsbyImage
              className="w-full h-full"
              image={
                getImage(data?.fields?.main_image?.localFile) ||
                getImage(data?.proxEntImg)
              }
              alt={data.matricula}
            />
          </div>
          <div className="p-1 flex-1">
            <div className="font-bold text-sm text-ellipsis line-clamp-2">
              {data.titulo}
            </div>
            <div>
              <span>Matrícula:</span> {data.matricula}
            </div>
            <div>
              <span>Fecha matriculación:</span>{' '}
              {formatDate(data.fecha_de_matriculacion)}
            </div>
          </div>
          <button
            aria-label="Eliminar de favoritos"
            className="bg-red-400 px-1.5 hover:bg-red-500 transition-all"
            type="button"
            onClick={(ev) => {
              ev.preventDefault();
              alternateFavorite(data.id);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-4 h-4 stroke-white"
            >
              <title>Eliminar</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </Link>
    </li>
  );
}
