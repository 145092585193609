// Dependencies
import React from 'react';
import Modal from '@components/modal';
import { Button, Recaptcha } from '@components';
import { IconBell } from '@tabler/icons-react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import useModalPriceDrop from './useModalPriceDrop';
import usePriceDrop from '@context/PriceDropContext/usePriceDrop';
import InputTextForm from '@components/inputTextForm';
import InputCheckbox from '@components/inputCheckbox';
import { LuSend } from 'react-icons/lu';
import PrivacityCheckbox from '@components/privacityCheckbox';

export default function ModalPriceDrop() {
  const intl = useIntl();
  const { isOpen, handleClose } = usePriceDrop();
  const { form, fetching, onSubmit, recaptchaRef } = useModalPriceDrop();
  const errors = form.formState.errors;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      center
      classNames={{
        modal: 'max-w-[500px] rounded-lg',
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-3 text-truckBlue">
          <IconBell />
          <span className="text-2xl">Aviso de bajada de precio</span>
        </div>
        <hr />
        <div className="flex flex-col gap-3 text-sm">
          <p>
            Rellene este formulario si desea que le avisemos mediante un email
            cuando este vehículo baje de precio:
          </p>
        </div>
        <hr />
        <form
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-2"
        >
          <InputTextForm
            isRequired
            id="full_name"
            label={intl.formatMessage({
              id: 'misc_full_name',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_full_name',
            })}
            errors={errors}
            {...form.register('full_name')}
          />
          <InputTextForm
            isRequired
            type="tel"
            id="phone"
            label={intl.formatMessage({
              id: 'misc_phone',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_phone',
            })}
            errors={errors}
            {...form.register('phone')}
          />
          <InputTextForm
            isRequired
            type="email"
            id="email"
            label={intl.formatMessage({
              id: 'misc_email',
            })}
            placeholder={intl.formatMessage({
              id: 'misc_email',
            })}
            errors={errors}
            {...form.register('email')}
          />
          <PrivacityCheckbox form={form} />
          <InputCheckbox
            defaultChecked={true}
            {...form.register('accept_newsletter')}
            id="accept_newsletter"
            label={intl.formatMessage({
              id: 'misc_accept_newsletter',
            })}
            errors={errors}
          />
          <hr className="my-1" />
          <div className="flex justify-end">
            <Button color="trucksur" type="submit" isLoading={fetching}>
              <div className="flex w-fit mx-auto">
                <LuSend className="h-5 w-5" />
                <div className="ml-[5px]">
                  <FormattedMessage id="misc_send" />
                </div>
              </div>
            </Button>
          </div>
        </form>
        <div className="hidden">
          <Recaptcha ref={recaptchaRef} />
        </div>
      </div>
    </Modal>
  );
}
