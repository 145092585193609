// Dependencies
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import classNames from 'classnames';
import categories from '@constants/categories';

// Icons
import CatTractorHead from './icons/categories/CatTractorHead';
import CatRigid from './icons/categories/CatRigid';
import CatConstruction from './icons/categories/CatConstruction';
import CatRefrigerated from './icons/categories/CatRefrigerated';
import CatSemitrailer from './icons/categories/CatSemitrailer';
import CatLight from './icons/categories/CatLight';
import CatBus from './icons/categories/CatBus';
import CatCars from './icons/categories/CatCars';
import CatParts from './icons/categories/CatParts';

const RENDER_ICONS = {
  cat_tractor_head: () => <CatTractorHead className="h-8" />,
  cat_rigid: () => <CatRigid className="h-8" />,
  cat_construction: () => <CatConstruction className="h-8" />,
  cat_refrigerated: () => <CatRefrigerated className="h-8" />,
  cat_semitrailer: () => <CatSemitrailer className="h-8" />,
  cat_light: () => <CatLight className="h-8" />,
  cat_bus: () => <CatBus className="h-8" />,
  cat_cars: () => <CatCars className="h-8" />,
  cat_parts: () => <CatParts className="h-8" />,
};

function CategoriesIcon({ id, count }) {
  return (
    <div className="h-full flex flex-col gap-1 justify-between items-center py-3 px-3 text-center text-trucksur-700 text-md font-medium">
      <div className="flex flex-col justify-start gap-1">
        {RENDER_ICONS[id] ? RENDER_ICONS[id]() : null}
        <div className="font-light text-[14px]">
          <FormattedMessage id={id} />
        </div>
      </div>
      {count ? <span className="text-sm">({count})</span> : null}
    </div>
  );
}

export default function CategoriesList({ className }) {
  const {
    allVehicleType: { edges: typesEdges },
  } = useStaticQuery(graphql`
    query {
      allVehicleType {
        edges {
          node {
            id
            count
          }
        }
      }
    }
  `);

  const types = typesEdges.map(({ node }) => node);
  const finalCategories = categories
    .filter(
      (item) => types.map((o) => o.id).includes(item.type) || item.isExternal
    )
    .map((item) => {
      const findedTypes = types?.find((type) => type.id === item.type);
      return {
        ...item,
        count: findedTypes?.count || 0,
      };
    });

  return (
    <div className={classNames('my-4 container mx-auto px-3', className)}>
      <div className="grid grid-cols-4 lg:grid-cols-8 gap-4 ">
        {finalCategories.map((item) => {
          return item.isExternal ? (
            <a
              alt="Enlace externo"
              className="w-full hover:no-underline"
              href={item.href}
              target="_blank"
              rel="noreferrer"
              key={item.key}
            >
              <CategoriesIcon id={item.key} />
            </a>
          ) : (
            <Link
              to={`/vehiculos/${item.seo}`}
              className="w-full hover:no-underline"
              key={item.key}
            >
              <CategoriesIcon id={item.key} count={item.count} />
            </Link>
          );
        })}
      </div>
      <div className="mt-1 h-1.5 bg-truckBlue" />
    </div>
  );
}
