// Dependencies
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import classNames from 'classnames';
import { VideoModal, CarouselModal, Button } from '@components';
import ButtonFavoriteHeart from './buttonFavoriteHeart';
import ButtonPriceDrop from './buttonPriceDrop';
import ImagesVehicleCarousel, {
  NextArrow,
  PrevArrow,
} from '@components/imagesVehicleCarousel';
import { calcMonthly } from '@lib/financial';
import { prettyNumber } from '@lib/utils';
import useFavorites from '@context/FavoritesContext/useFavorites';
import { IconFileTypePdf } from '@tabler/icons-react';

function ButtonVideo({ data, ...props }) {
  return (
    <button
      type="button"
      className="absolute bottom-12 right-8 py-2 px-3 rounded-full text-white bg-opacity-80 bg-trucksur-700 z-[30] hover:bg-opacity-100 transition-all"
      {...props}
    >
      <div className="flex gap-2 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-7 h-7"
        >
          <title>Video</title>
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
            clipRule="evenodd"
          />
        </svg>
        <span className="text-lg">Ver vídeo</span>
      </div>
    </button>
  );
}

export default function VehiclesCarousel({
  handleClickDatasheet,
  downloadingDatasheet,
  data,
  className,
  ...props
}) {
  const intl = useIntl();
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [isOpenImages, setIsOpenImages] = useState(false);
  const { vehicle, financial } = data;
  const isReserved = vehicle.estado === 'Reservado';
  const isSold = vehicle.estado === 'Vendido';
  const isBankable = vehicle.es_financiable;
  const interest = financial.interest;

  const monthlyFee = isBankable
    ? calcMonthly({
        amount: vehicle.financ_pvp,
        entry: vehicle.financ_entrada,
        months: vehicle.financ_plazo,
        residualValue: vehicle.financ_valor_res,
        interest,
      })
    : null;

  const images = vehicle?.fields?.images
    ?.filter((item) => item)
    .map((img, index) => {
      return {
        alt: vehicle.seo_name,
        image: img,
        order: index + 1,
      };
    });

  const [initialIndexSlide, setInitialIndexSlide] = useState(0);
  const youtubeVideo = vehicle?.fields?.video_meta;

  const slickProps = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
  };

  function handleClickImage({ index }) {
    setInitialIndexSlide(index);
    setIsOpenImages(true);
  }

  const handleCloseCarousel = useCallback(() => {
    setIsOpenImages(false);
  }, [setIsOpenImages]);

  const handleCloseVideo = useCallback(() => {
    setIsOpenVideo(false);
  }, [setIsOpenVideo]);

  const { isFavorite, alternateFavorite } = useFavorites();

  return (
    <>
      <div
        className={classNames(
          'rounded-xl overflow-hidden relative mb-3 w-full max-w-[640px] mx-auto',
          className
        )}
        {...props}
      >
        <div className="relative">
          {isReserved || isSold ? (
            <div className="absolute z-[20] top-[26px] -left-[42px]">
              <div className="rotate-[-45deg] bg-trucksur-600 text-white font-bold py-1 w-[170px] text-center">
                {intl.formatMessage({ id: 'misc_sold' }).toUpperCase()}
              </div>
            </div>
          ) : null}
          {youtubeVideo && (
            <ButtonVideo
              data={youtubeVideo}
              onClick={() => setIsOpenVideo(true)}
            />
          )}
          {isBankable && (
            <div className="flex justify-between absolute w-full bg-green-700 bg-opacity-80 text-white z-[20] bottom-0 text-md py-1 px-2">
              <div className="font-medium">Financiable</div>
              <div className="font-light">
                {prettyNumber(
                  monthlyFee,
                  0,
                  intl.formatMessage({ id: 'financing.euro_month' })
                )}
              </div>
            </div>
          )}
          <div className="absolute right-0 top-0 flex flex-col gap-4 z-50 pt-[20px] pr-[20px]">
            <ButtonFavoriteHeart
              className="h-10 w-10 md:w-12 md:h-12"
              id={vehicle.id}
            />
            <ButtonPriceDrop
              className="h-10 w-10 md:w-12 md:h-12"
              data={vehicle}
            />
          </div>

          <ImagesVehicleCarousel
            images={images}
            slickProps={slickProps}
            handleClick={handleClickImage}
            isBankable={isBankable}
            className="cursor-zoom-in"
          />
        </div>

        <div className="grid grid-cols-2">
          <button
            onClick={() => alternateFavorite(vehicle.id)}
            type="button"
            className="py-2 btn rounded-none bg-red-600 text-white font-medium text-sm md:text-md active:ring-0 focus:ring-0"
          >
            {!isFavorite(vehicle.id)
              ? intl.formatMessage({ id: 'vehicle.add_favorito' })
              : intl.formatMessage({ id: 'vehicle.remove_favorito' })}
          </button>
          <Button
            aria-label={intl.formatMessage({ id: 'vehicle.descarga_ficha' })}
            onClick={handleClickDatasheet}
            className="py-2 !px-3 w-full btn !rounded-none bg-trucksur-600 hover:bg-trucksur-600  text-white font-medium text-sm md:text-md active:ring-0 focus:ring-0"
            isLoading={downloadingDatasheet}
          >
            <div className="flex justify-center items-center gap-2">
              {!downloadingDatasheet ? <IconFileTypePdf /> : null}
              <span>
                <FormattedMessage id="vehicle.descarga_ficha" />
              </span>
            </div>
          </Button>
        </div>
      </div>

      <VideoModal
        isOpen={isOpenVideo}
        handleClose={handleCloseVideo}
        data={youtubeVideo}
      />
      <CarouselModal
        isOpen={isOpenImages}
        handleClose={handleCloseCarousel}
        data={images}
        initialIndexSlide={initialIndexSlide}
      />
    </>
  );
}
