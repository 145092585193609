// Dependencies
import { useState, useRef } from 'react';
import * as yup from 'yup';
import { useIntl } from 'gatsby-plugin-intl';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchCreateNewsletter } from '@api/operations';

export default function useFooter() {
  const [fetching, setFetching] = useState(false);
  const intl = useIntl();
  const recaptchaRef = useRef();

  const INITIAL_VALUES = { email: '' };

  const schema = yup.object({
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'validation_email' }))
      .required(intl.formatMessage({ id: 'validation_required' })),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    initialValues: INITIAL_VALUES,
  });

  const onSubmit = async (values) => {
    setFetching(true);
    try {
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      await fetchCreateNewsletter({ ...values, recaptchaToken });

      setFetching(false);
      toast('✅ Email introducido correctamente', {
        className: 'text-sm',
      });
      form.reset();
    } catch (err) {
      console.log('se produjo un error');
      toast('❌ Se produjo un error, vuelva a intentarlo', {
        className: 'text-sm',
      });
      setFetching(false);
    }
  };

  return {
    form,
    onSubmit,
    fetching,
    recaptchaRef,
  };
}
