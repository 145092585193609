// Dependencies
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import useCalculator from '@hooks/useCalculator';
import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  InputTextForm,
  InputSelectForm,
  InputRangeSlider,
  Button,
} from '@components';

function VehiclesCalculatorForm({
  data,
  calculatorConfig,
  applyFilters,
  resetFilters,
  defaultValues,
}) {
  const intl = useIntl();

  const {
    values,
    control,
    register,
    handleSubmit,
    handleReset,
    labels,
    types,
    subcategories,
    brands,
    antiquity,
    kms,
  } = useCalculator({
    applyFilters,
    resetFilters,
    defaultValues,
    data,
    calculatorConfig,
  });

  return (
    <div className="border rounded-xl px-3 py-4 my-[1rem] shadow-md">
      <form
        className="grid grid-cols-1 gap-4"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <InputSelectForm
            id="type"
            label={intl.formatMessage({ id: 'vehicle.tipo_de_vehiculo' })}
            values={types}
            {...register('type')}
          />

          {(values.type === 'Vehículos ligeros' ||
            values.type === 'Vehículos Rígidos') && (
            <InputSelectForm
              id="subcategory"
              label={intl.formatMessage({ id: 'misc_subcategory' })}
              values={subcategories}
              {...register('subcategory')}
            />
          )}
          <InputSelectForm
            id="brand"
            label={intl.formatMessage({ id: 'vehicle.marca' })}
            values={brands}
            {...register('brand')}
          />
          <InputTextForm
            id="model"
            label="Modelo"
            placeholder="Modelo"
            {...register('model')}
          />
          <div>
            <InputSelectForm
              id="antiquity"
              label={intl.formatMessage({ id: 'misc_antiquity' })}
              values={antiquity}
              {...register('antiquity')}
            />
            {values.antiquity === 'between' && (
              <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                <InputTextForm
                  type="date"
                  id="antiquity_since"
                  placeholder="Desde"
                  {...register('antiquity_since')}
                />
                <InputTextForm
                  type="date"
                  id="antiquity_until"
                  placeholder="Hasta"
                  {...register('antiquity_until')}
                />
              </div>
            )}
          </div>
          <div>
            <InputSelectForm
              id="kms"
              label={intl.formatMessage({ id: 'misc_kilometres' })}
              values={kms}
              {...register('kms')}
            />
            {values.kms === 'between' && (
              <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                <InputTextForm
                  id="kms_since"
                  placeholder="Desde"
                  {...register('kms_since')}
                />
                <InputTextForm
                  id="kms_until"
                  placeholder="Hasta"
                  {...register('kms_until')}
                />
              </div>
            )}
          </div>
        </div>
        <hr className="my-1" />
        <div className="grid grid-cols-1 gap-6">
          <InputRangeSlider
            label={labels.entrada}
            control={control}
            name="entrada"
            defaultValue={[0, 0]}
            min={0}
            max={calculatorConfig.ENTRADAS.length - 1}
          />
          <InputRangeSlider
            label={labels.plazo}
            control={control}
            name="plazo"
            defaultValue={[0, 0]}
            min={0}
            max={calculatorConfig.PLAZOS.length - 1}
          />
          <InputRangeSlider
            label={labels.cuota}
            control={control}
            name="cuota"
            defaultValue={[0, 0]}
            min={0}
            max={calculatorConfig.CUOTAS.length - 1}
          />
          <div className="bg-truckBlue mt-2 px-4 py-2.5 rounded-xl text-md text-white">
            <span className="mr-1.5">Precio aproximado:</span>
            <b>{labels.aproximated}</b>
          </div>
        </div>
        <hr />
        <p className="text-sm text-truckBlue">
          <FormattedMessage id="financing.text_condition" />
        </p>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <Button type="submit" color="sky" className="w-full md:max-w-[240px]">
            <FormattedMessage id="misc_apply_filter" />
          </Button>
          <Button
            color="red"
            onClick={handleReset}
            className="w-full md:max-w-[240px]"
          >
            <FormattedMessage id="misc_reset_filter" />
          </Button>
        </div>
      </form>
    </div>
  );
}

export default VehiclesCalculatorForm;
