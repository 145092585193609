// Dependencies
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

const InputSelectForm = React.forwardRef(
  ({ className, isRequired, label, errors, values, ...props }, ref) => {
    const { id, name } = props;
    const textLabel = isRequired ? `* ${label}` : label;

    return (
      <div className={className}>
        {label ? (
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor={id}
          >
            {textLabel}
          </label>
        ) : (
          false
        )}
        <div className="relative">
          <select
            ref={ref}
            className="bg-white text-sm block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
            id={id}
            {...props}
          >
            {values?.map(({ value, label, hidden }) => {
              return (
                <option key={value} value={value} hidden={hidden}>
                  {label}
                </option>
              );
            })}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>chevron</title>
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        {errors && (
          <div className="text-red-600 text-sm mt-1.5 font-light">
            <ErrorMessage errors={errors} name={name} />
          </div>
        )}
      </div>
    );
  }
);

export default InputSelectForm;
